import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { Menu, Popup } from "semantic-ui-react";
import { ReactComponent as SearchIcon } from "../../../../images/Search.svg";
import { Actions } from "../../../../services/audits/actions.jsx";
import PanelWrapper from "../../../common/panelWrapper.jsx";
import SearchPanel from "./searchPanel";
import "../audit/styles.scss";

class SearchBar extends React.Component {

  getContent = () => {
    let { t } = this.props;
    if (this.props.activeItem === 'search') {
      return (
        <PanelWrapper
          key="s1"
          title={t("search.search")}
          cancel={this.props.cancel}
        >
          <SearchPanel/>
        </PanelWrapper>
      );
    }
    return null;
  }

  render() {
    var { t } = this.props;
    return (
      <>
        <Popup
          className="popup"
          mouseEnterDelay={300}
          trigger={
            <Menu.Item
              className="icon-container"
              name="search"
              active={this.props.activeItem === "search"}
              onClick={this.props.onClick}
            >
              <SearchIcon className="sidebar-icon" />
            </Menu.Item>
          }
          position={"right center"}
        >
          {t("search.search")}
        </Popup>

        <div className="menu-option">{this.getContent()}</div>
      </>
    )
  }
}

export default compose(
  withTranslation()
)(SearchBar);
