/*
    ./client/components/App.jsx
*/
import domtoimage from "dom-to-image";
import FileSaver from "file-saver";
import JSZip from "jszip";
import React from "react";
import PdfBuilder from "./pdfBuilder.jsx"
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { Button, Dropdown, Input, Message, Radio } from "semantic-ui-react";
import "whatwg-fetch";
import Requests from '../../../../services/requests';
import { ResultsActions } from "../../../../services/results/actions.jsx";
import PdfExportInputModal from "./pdfExportInputModal.jsx";
import "../styles.scss";

const dataTypeLayerMap = {
  "CONCENTRATION": "concentration",
  "DEPOSITION": "deposition",
  "DOSAGE": "dosage",
  "ECT50": "probability_effects_mild",
  "ICT50": "probability_effects_severe",
  "LCT50": "probability_effects_death",
  "AEGLS": "aegl",
  "BLAST": "blast",
  "ATP-45": "atp45"
}

const nonDispersiveDataTypes = [
  "BLAST",
  "ATP-45"
]

class SimulationFilterMenu extends React.Component {
  constructor(props) {
    super(props);

    var { t } = props;

    var options = [
      {
        key: "png",
        text: t("export.exportToPng"),
        value: "png",
      },
      {
        key: "kml",
        text: t("export.exportToKml"),
        value: "kml",
      },
      {
        key: "pdf",
        text: t("export.exportToPdf"),
        value: "pdf",
      },
    ];

    this.state = {
      options: options,
      exportType: "png",
      name: "",
      exportNameType: "default",
      showPdfExportInputModal: false,
      loading: false,
      error: false,
    };
  }

  componentDidUpdate = (prevProps, prevState) => {
    var performValidation = false;

    if (
      prevState.name != this.state.name ||
      prevState.exportNameType != this.state.exportNameType
    ) {
      performValidation = true;
    }
    if (performValidation) {
      this.validateNameInput();
    }
  };

  getSimulations = () => {
    var data = [];
    var simulations = this.props.simulationState.simulations;
    for (let i = 0; i < simulations.length; i++) {
      let simulation = simulations[i]; // eslint-disable-line security/detect-object-injection
      data.push({
        key: i,
        text: simulation.name + " " + simulation.computationTimeElapsed,
        value: i,
      });
    }
    return data;
  };

  getDataTypes = () => {
    var { t } = this.props;
    if (this.props.simulationState.selectedSimulation) {
      var dataTypes = this.props.resultsState.dataTypes[
        this.props.simulationState.selectedSimulation.id
      ];
      if (dataTypes) {
        return dataTypes.map((data) => {
          return {
            key: data.id,
            text: t("dataTypes." + data.name),
            value: data.id,
          };
        });
      }
    }
    return [];
  };

  getMaterials = () => {
    if (this.props.simulationState.selectedSimulation) {
      var materials = this.props.resultsState.materials[
        this.props.simulationState.selectedSimulation.id
      ];
      if (materials) {
        return materials.map((material) => {
          return { key: material.id, text: material.name, value: material.id };
        });
      }
    }
    return [];
  };

  exportToPng = () => {
    this.setState({ loading: true });
    var { t } = this.props;

    var width = this.state.width;
    var height = this.state.height;

    const name = this.state.exportNameType === "default" ?
      this.createSimulationFileName() :
      this.state.name;

    domtoimage
      .toBlob(this.props.mapState.map._container.parentNode, { width, height })
      .then((blob) => {
        FileSaver.saveAs(blob, `${name}.png`);
        this.setState({ loading: false, error: null });
      })
      .catch((err) => {
        console.error(err);
        this.setState({
          loading: false,
          error: t("export.messages.failedToExportPNG"),
        });
      });
  };

  getContourType = (dataTypeEnum) => {
    return dataTypeLayerMap[dataTypeEnum]; // eslint-disable-line security/detect-object-injection
  }

  exportToKml = async () => {
    this.setState({ loading: true });
    let { t } = this.props;
    let simId = this.props.simulationState.selectedSimulation.id;

    let baseFilter = "simulation_id = " + simId;
    let filter = baseFilter;
    let time = new Date(this.props.mapState.currentTime).toISOString();

    // Take clones of the props arrays in order not to modify objects on props directly
    let selectedDataTypes = this.props.resultsState.selectedDataTypes.slice(0);

    // Get selected materials based on their ID being in selected materials array
    let selectedMaterials = this.props.resultsState.materials[simId].filter( // eslint-disable-line security/detect-object-injection
      (material) => {
        return this.props.resultsState.selectedMaterials.includes(material.id);
      }
    );

    // If no data types are selected, this is viewed as all in the UI so all
    // data types are added. Same applies for materials
    if (selectedDataTypes.length === 0) {
      this.props.resultsState.dataTypes[simId].forEach((element) => { // eslint-disable-line security/detect-object-injection
        selectedDataTypes.push(element.id);
      });
    }
    if (selectedMaterials.length === 0) {
      this.props.resultsState.materials[simId].forEach((element) => { // eslint-disable-line security/detect-object-injection
        selectedMaterials.push(element);
      });
    }

    // If there is material selected - update filter query
    if (selectedMaterials && selectedMaterials.length > 0) {
      filter += " AND material_id IN (:material_id)";
    }

    let zip = new JSZip();
    let numberOfExpectedFiles =
      selectedDataTypes.length * selectedMaterials.length;

    if (numberOfExpectedFiles === 0) {
      this.setState({ loading: false, error: null });
    }

    // Iterate over each data type, then for each data type iterate over
    // each material, this is so that a separate KML file can be created for
    // each combination of data type and material
    // Handle dispersive data types here
    for (let id in selectedDataTypes) {
      for (let mIndex in selectedMaterials) {
        let material = selectedMaterials[mIndex]; // eslint-disable-line security/detect-object-injection
        let data_type_id = selectedDataTypes[id]; // eslint-disable-line security/detect-object-injection
        let contourName = "";

        if (!material.id) {
          continue;
        }

        this.props.resultsState.dataTypes[simId].map((element) => { // eslint-disable-line security/detect-object-injection
          if (data_type_id == element.id) {
            contourName = element.name;
          }
        });

        // Skip if selected data type is non dispersive
        if (nonDispersiveDataTypes.includes(contourName)) {
          continue;
        }

        // Create the file name for the KML file
        const name = `${this.createSimulationFileName()}-${material.name}-${contourName}`;

        try {
          await this.addKmlIntoZipFile(contourName, filter.replace(":material_id", material.id), time, name, zip);
        } catch (err) {
          return;
        }
      }
    }

    // Loop through selected data types and handle non dispersive ones
    for (let id in selectedDataTypes) {
      let data_type_id = selectedDataTypes[id]; // eslint-disable-line security/detect-object-injection
      let contourName = "";

      this.props.resultsState.dataTypes[simId].map((element) => { // eslint-disable-line security/detect-object-injection
        if (data_type_id == element.id) {
          contourName = element.name;
        }
      });

      // Skip if selected data type is dispersive (handled above)
      if (!nonDispersiveDataTypes.includes(contourName)) {
        continue;
      }

      // Create the file name for the KML file
      const name = `${this.createSimulationFileName()}-${contourName}`;

      try {
        await this.addKmlIntoZipFile(contourName, baseFilter, time, name, zip);
      } catch (err) {
        return;
      }
    }

    // Loop through selected data types and handle non dispersive ones
    for (let id in selectedDataTypes) {
      let data_type_id = selectedDataTypes[id]; // eslint-disable-line security/detect-object-injection
      let contourName = "";

      this.props.resultsState.dataTypes[simId].map((element) => { // eslint-disable-line security/detect-object-injection
        if (data_type_id == element.id) {
          contourName = element.name;
        }
      });

      // Skip if selected data type is dispersive (handled above)
      if (!nonDispersiveDataTypes.includes(contourName)) {
        continue;
      }

      // Create the file name for the KML file
      const name = `${this.createSimulationFileName()}-${contourName}`;

      try {
        await this.addKmlIntoZipFile(contourName, baseFilter, time, name, zip);
      } catch (err) {
        return;
      }
    }

    // Define the generated zip file name, or use the custom user filename if specified:
    var zipFileName = `${this.props.scenarioState.scenario.name}-Contours.zip`;
    if (this.state.exportNameType === "custom") {
      zipFileName = `${this.state.name}.zip`;
    }

    // If the files were zipped up successfully, the zip file can be downloaded
    zip.generateAsync({ type: "blob" }).then((content) => {
      FileSaver.saveAs(content, zipFileName);
    });
    this.setState({ loading: false, error: null });
  };

  /**
   * Exports data and adds it to the zip folder
   * @param {String} contourName contour name
   * @param {String} filter CQL filter
   * @param {Date} time time of data to export
   * @param {String} filename file name
   * @param {Zip} zip zip folder
   */
  addKmlIntoZipFile = async (contourName, filter, time, filename, zip) => {
    const geoserverUrl = window.env.REACT_APP_GEOSERVER_URL;
    const timeList = this.props.mapState.map.timeDimension.getAvailableTimes().map(time => new Date(time).toISOString());
    const viewparams = [
      'key:' + this.props.userState.user.organisation.key
    ];
    let viewParamUrl = geoserverUrl + "/geoserver/urban_aware/wms?viewparams=" + viewparams.join(';');
    await this.exportAsKML(
      viewParamUrl +
      "&service=WMS&version=1.1.0" +
      `&request=GetMap&layers=urban_aware:${this.getContourType(contourName)}&${this.createBoundingBox()}&` +
      `width=768&height=384&srs=EPSG%3A4326&format=application%2Fvnd.google-earth.kml&mode=download` +
      `&CQL_FILTER=${filter}&time=${timeList.join()}`,
      filename,
      zip
    ).catch((err) => {
      this.setState({
        loading: false,
        error: t("export.messages.failedToExportKML"),
      });
      throw err;
    });
  }

  createBoundingBox = () => {
    const mapBounds = this.props.mapState.map.getBounds();
    return "bbox=" + mapBounds.getSouthWest().lng + "%2C" + mapBounds.getSouthWest().lat + "%2C" + mapBounds.getNorthEast().lng + "%2C" + mapBounds.getNorthEast().lat;
  }

  exportAsKML = async (url, name, zipFile) => {
    // Geoserver requests are not authenticated, hence this does not require to go via
    // centralized Requests class
    await fetch(url)
      .then((res) => {
        return res.text().then((req) => {
          var blob = new Blob([req], {
            type: "text/plain;charset=utf-8",
          });
          zipFile.file(`${name}.kml`, blob);
        });
      })
      .catch((err) => {
        console.error(err);
        throw Error(err);
      });
  };

  /**
   * Requests ATP45 contour data for the current simulation from the server
   * @param {Number} scenarioId the id of the current scenario
   * @param {Number} simulationId the id of the current simulation
   * @returns {Array[Object]} the contourData from the server
   */
  async _getAtp45ContourData(scenarioId, simulationId) {
    const url = '/output/scenario/' + scenarioId + '?';
    const queryParams = {
      includeGeometry: true,
      simulationId: simulationId,
      dataTypeId: 11, // ATP45
    };
    return await Requests.get(url + new URLSearchParams(queryParams));
  }

  exportToPdf = (additionalNotes) => {
    this.setState({ loading: true });
    const filename = this.state.exportNameType === "default" ?
      `${this.createSimulationFileName()}.pdf` :
      `${this.state.name}.pdf`;

    const nodeToCapture = this.props.mapState.map._container.parentNode
    domtoimage.toPng(nodeToCapture).then(pngData => {
        this._getAtp45ContourData(
          this.props.scenarioState.scenario.id,
          this.props.simulationState.selectedSimulation.id
        ).then(atp45ContourData => {
          const doc = new PdfBuilder(this.props.t, this.props.userState.user.preferences).build(
            pngData,
            nodeToCapture.clientWidth,
            nodeToCapture.clientHeight,
            this.props.selectedMapTime,
            additionalNotes,
            this.props.scenarioState,
            this.props.simulationState,
            this.props.resultsState,
            this.props.incidentState,
            this.props.metState,
            atp45ContourData,
            this.props.aoiState,
            this.props.userState
          );
          doc.save(filename)
          this.setState({ loading: false, error: null });
        })
        .catch((err) => {
          console.error(err);
          this.setState({ loading: false, error: this.props.t("app.error") });
        });
      })
      .catch((err) => {
        console.error(err);
        this.setState({ loading: false, error: this.props.t("app.error") });
      });

  }

  createSimulationFileName = () => {
    const time = new Date(this.props.mapState.currentTime).toISOString();
    return `${this.props.scenarioState.scenario.name}-${this.props.simulationState.selectedSimulation.name}-${time}`
  }

  selectMaterial = (value) => {
    this.props.selectMaterials(value);
  };

  selectDataType = (value) => {
    this.props.selectDataTypes([value]);
  };

  setExportType = (e, data) => {
    this.setState({
      exportType: data.value,
    });
  };

  export = () => {
    switch(this.state.exportType) {
      case "png":
        this.exportToPng()
        break
      case "kml":
        this.exportToKml()
        break
      case "pdf":
        this.setState({ showPdfExportInputModal: true })
        break
    }
  };

  exportTranslation = () => {
    var { t } = this.props;

    switch (this.state.exportType) {
      case "png":
        return t("export.exportToPng");
      case "kml":
        return t("export.exportToKml");
      case "pdf":
        return t("export.exportToPdf");
      default:
        return null;
    }
  };

  customName = () => {
    var { t } = this.props;
    if (this.state.exportNameType === "custom") {
      return (
        <>
          <label>{t("export.exportFileName")}:</label>
          <br />
          <Input
            className="input"
            error={this.state.error}
            value={this.state.name}
            onChange={(v) => this.setState({ name: v.target.value })}
          />
        </>
      );
    }
    return null;
  };

  validateExportButton = () => {
    var str = this.state.name;
    var simId = this.props.simulationState.selectedSimulation.id;

    if (!str.trim().length && this.state.exportNameType === "custom") {
      return false;
    }
    if (this.state.loading) {
      return false;
    }
    if (this.props.resultsState.materials[simId] === undefined || this.props.resultsState.materials[simId] === null) {
      return false;
    }
    return true;
  };

  validateNameInput = () => {
    var { t } = this.props;
    var str = this.state.name;

    if (this.state.exportNameType === "custom" && !str.trim().length) {
      this.setState({ error: t("export.nameCannotBeEmpty") });
    } else {
      this.setState({ error: null });
    }
  };

  getSimulationOptions = () => {
    const { t } = this.props;
    if (this.props.simulationState.selectedSimulation) {
      let selectedDataType = null;
      if (this.props.resultsState.selectedDataTypes && this.props.resultsState.selectedDataTypes.length > 0) {
        // The selected data types are stored in an array, so get the first choice
        selectedDataType = this.props.resultsState.selectedDataTypes[0];
      }
      // Return a dropdown of multiselect of materials in the simulation and the types of contours
      return (
        <>
          <PdfExportInputModal
            open={this.state.showPdfExportInputModal}
            onConfirm={input => {
              this.setState({ showPdfExportInputModal: false });
              this.exportToPdf(input);
            }}
            onCancel={() => this.setState({ showPdfExportInputModal: false })}
          />
          <div className="content-body simulation-filter">
            <label>{t("simulationFilterTool.contourType")}:</label>
            <Dropdown
              placeholder={t("simulationFilterTool.all")}
              fluid
              selection
              value={selectedDataType}
              options={this.getDataTypes()}
              onChange={(e, data) => this.selectDataType(data.value)}
            />
            <label>{t("simulationFilterTool.contourMaterial")}:</label>
            <Dropdown
              placeholder={t("simulationFilterTool.all")}
              clearable
              multiple
              fluid
              selection
              value={this.props.resultsState.selectedMaterials}
              options={this.getMaterials()}
              onChange={(e, data) => this.selectMaterial(data.value)}
            />
            <label>{t("export.exportType")}:</label>
            <Dropdown
              fluid
              selection
              value={this.state.exportType}
              options={this.state.options.map((e) => {
                return { key: e.key, text: e.text, value: e.value };
              })}
              onChange={(e, data) => this.setExportType(e, data)}
            />
            <div className="export-name-radio">
              <Radio
                label={t("export.autoFileName")}
                name="nameGroup"
                value="default"
                checked={this.state.exportNameType === "default"}
                onChange={(e, { value }) =>
                  this.setState({ exportNameType: value })
                }
              />
              <Radio
                label={t("export.customFileName")}
                name="nameGroup"
                value="custom"
                checked={this.state.exportNameType === "custom"}
                onChange={(e, { value }) =>
                  this.setState({ exportNameType: value })
                }
              />
            </div>
            {this.customName()}
            {this.state.error ? (
              <Message negative className="ua-error">
                <Message.Header>{t("app.error")}</Message.Header>
                <p>{this.state.error}</p>
              </Message>
            ) : null}
          </div>
          <div className="bottom-bar">
            <div className="finished">
              <Button
                loading={this.state.loading}
                onClick={this.export}
                disabled={!this.validateExportButton()}
              >
                {this.exportTranslation()}
              </Button>
            </div>
          </div>
        </>
      );
    }
    return null;
  };

  render() {
    return this.getSimulationOptions();
  }
}

/*
 * Maps state from the store to properties used by this class
 */
const mapStateToProps = (store, props) => {
  return {
    scenarioState: store.scenarioState,
    simulationState: store.simulationState,
    metState: store.metState,
    aoiState: store.aoiState,
    incidentState: store.incidentState,
    resultsState: store.resultsState,
    userState: store.userState,
    mapState: store.mapState,
    selectedMapTime: store.mapState.currentTime,
    ...props,
  };
};

/*
 * Maps properties to dispatch methods to send actions to the store reducers
 */
const mapDispatchToProps = (dispatch) => {
  return {
    selectDataTypes: (ids) => {
      dispatch(ResultsActions.selectDataTypes(ids));
    },
    selectMaterials: (ids) => {
      dispatch(ResultsActions.selectMaterials(ids));
    },
  };
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(SimulationFilterMenu);
