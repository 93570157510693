import React from 'react';
import Draggable from 'react-draggable';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import '../map.scss';
import BarConcentrationChart from './BarConcentrationChart';

class DetectorPopup extends React.Component {

    constructor(props) {
        super(props);

        // Arrange readings by material
        let readingsByMaterial = this.sortReadingsByMaterial(props.sensor.readings);

        this.state = {
            position: props.position,
            readingsByMaterial: readingsByMaterial,
            sensor: props.sensor,
            width: window.innerWidth,
            height: window.innerHeight,
        };
        this.wrapperRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
        window.addEventListener('resize', this.updateDimensions);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
        window.removeEventListener('resize', this.updateDimensions);
    }

    updateDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    };

    componentDidUpdate(prevProps) {
        if (prevProps.sensor.readings !== this.props.sensor.readings) {
            let readingsByMaterial = this.sortReadingsByMaterial(this.props.sensor.readings);
            this.setState({ readingsByMaterial: readingsByMaterial });
        }
    }

    sortReadingsByMaterial(readings) {
        let readingsByMaterial = [];
        // TimeSeries was passed non-chronological events
        for (let i = 0; i < readings.length; i++) {
            let reading = readings[i];
            const material = reading["materialName"];
            if(material) {
                if (!(material in readingsByMaterial)) {
                    readingsByMaterial[material] = [{
                        x: new Date(reading.time).getTime(),
                        y: reading.concentrationReading
                    }];
                } else {
                    readingsByMaterial[material].push({
                        x: new Date(reading.time).getTime(),
                        y: reading.concentrationReading
                    });
                    readingsByMaterial[material].sort(function (a, b) { return a.x - b.x });
                }
            }
        }
        return readingsByMaterial;
    }

    formatTime = (v) => {
        return new Date(v).toLocaleTimeString('en-GB', {
            hour: "numeric",
            minute: "numeric"
        });
    }

    intToRGB = (str) => {

        let hash = 0;
        for (var i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }

        return `hsl(${hash % 360}, 100%, ${55 + (hash % 22)}%)`;
    }

    getSensorInfo = () => {
        const { t } = this.props;
        var sensor = this.props.sensor;

        //let status = this.props.concentration > 3 ? t("sensor.alert") : t("sensor.sampling");
        let status = this.props.status; // TODO translate
        let concentration = this.props.concentration;
        let location = this.props.position[0] + ", " + this.props.position[1];

        return (
            <div style={{ "fontSize": "14px", "padding": "15px" }}>
                <table style={{ width: "50%" }}>
                    <tr key={"sensor-time" + sensor.deviceId}>
                        <th>{t("sensor.chart.time")}</th>
                        <td>{this.formatTime(new Date())}</td>
                    </tr>
                    <tr key={"sensor-location" + sensor.deviceId}>
                        <th>{t("sensor.location")}</th>
                        <td>{location}</td>
                    </tr>
                    <tr key={"sensor-status" + sensor.deviceId}>
                        <th>{t("sensor.status")}</th>
                        <td>{status}</td>
                    </tr>
                    <tr key={"sensor-conc" + sensor.deviceId}>
                        <th>Reading:</th>
                        <td>{concentration} bar</td>
                    </tr>
                </table>
            </div>
        )
    }

    getErrors = () => {
        var sensorErrors = this.props.sensorErrors;
        if (sensorErrors) {
            return (
                <div style={{ "fontSize": "14px", "padding": "20px" }}>
                    <div>
                        <span style={{ "fontWeight": "800" }}>{sensorErrors}
                        </span>
                    </div>
                </div>
            );
        }
        return null;
    }

    /**
     * Alert if clicked on outside of element
     */
    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
            this.props.hidePopup();
        }
    }

    render() {
        const { t } = this.props;
        var sensor = this.props.sensor;

        // Create a Draggable component bounds that will not allow to drag the popup
        // outside the visible window, with 200 pixels of the popup always visble
        return (
            <Draggable
                bounds={{
                    left: -this.state.position.x - 200,
                    top: -this.state.position.y - 200,
                    right: this.state.width - this.state.position.x - 200,
                    bottom: this.state.height - this.state.position.y - 200,
                }}>
                <div className="leaflet-popup-content-wrapper ua-popup sensors" ref={this.wrapperRef}
                    style={{
                        left: this.state.position.x + 'px',
                        top: this.state.position.y + 'px'
                    }}>
                    <div style={{ "textAlign": "center" }}>
                        <span style={{ "fontWeight": "800", "fontSize": "16px" }}>{t("sensor.deviceId")}: {sensor.deviceId}</span>
                    </div>
                    <div >
                        {this.getSensorInfo()}
                        <BarConcentrationChart
                            readingsByMaterial={this.state.readingsByMaterial}
                            intToRGB={this.intToRGB}
                            sensorId={sensor.id}
                            formatTime={this.formatTime} />
                        {this.getErrors()}
                    </div>
                </div>
            </Draggable>
        )
    }
}

const mapStateToProps = (store) => {
    return {
        mapState: store.mapState
    }
}

export default compose(withTranslation(), connect(mapStateToProps, null))(DetectorPopup);