import React from 'react';
import { LayerGroup, WMSTileLayer, LayersControl } from 'react-leaflet';
import RasterDataLegend from '../legends/RasterDataLegend.jsx';

const { Overlay, } = LayersControl
const GEOSERVER_TERRAIN_LAYER_NAME = 'terrain'

export class TerrainLayers {

    static getMapLayers(t) {
        // UA Geoserver URL
        const url = window.env.GEO_DATA_URL + '/geoserver/geo-data/wms';

        return (
            <Overlay name={t("map.terrain")}>
                <LayerGroup id="terrainLayer" >
                    <WMSTileLayer
                        url={url}
                        layers={GEOSERVER_TERRAIN_LAYER_NAME}
                        format='image/png'
                        transparent='true'
                        opacity="0.7"
                    />
                </LayerGroup>
            </Overlay>
        )
    }

    static getLegend(t) {
        return <RasterDataLegend
            layerName="terrainLayer"
            layerTitle={t("map.terrain")}
            noDataLabel={t("map.noTerrainData")}
            url="/legends/terrain/"
            unitsLabel="m"
        />;
    }
}