/*
    ./client/components/App.jsx
*/
import React from "react";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import { Menu, Popup } from "semantic-ui-react";
import { ReactComponent as SimulationConfigIcon } from '../../../images/Cog.svg';
import RunSimulationPanel from "./runSimulationPanel";

/**
 * Button to control whether the runSimulation displaying/editing views are visible
 */
class SimulationConfigMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      next: props.next
    };
  }

  getContent = () => {
    if (this.props.activeItem === this.props.name) {
      return (
        <RunSimulationPanel title={this.props.title} cancel={this.props.cancel} next={this.state.next} />
      );
    }
    return null;
  };

  render() {
    return (
      <>
        <Popup
          className="popup"
          mouseEnterDelay={300}
          trigger={
            <Menu.Item
              className="icon-container"
              name={this.props.name}
              active={this.props.activeItem === this.props.name}
              onClick={this.props.onClick}
            >
              <SimulationConfigIcon className="sidebar-icon" />
            </Menu.Item>
          }
          position={"right center"}
        >
          {this.props.title}
        </Popup>

        <div className="menu-option run-config">{this.getContent()}</div>
      </>
    );
  }
}

export default compose(withTranslation())(SimulationConfigMenu);
