import Requests from '../requests';

/*
* action types
*/
export const FETCH_METS_BEGIN = 'FETCH_METS_BEGIN';
export const FETCH_METS_SUCCESS = 'FETCH_METS_SUCCESS';
export const FETCH_METS_FAILURE = 'FETCH_METS_FAILURE';

/**
 * Creates actions for met 
 */
export class Actions {

	/*
	* action creator to start getting met
	*/
	static fetchMetsBegin = () => ({
		type: FETCH_METS_BEGIN
	});

	/*
	* action creator to indicate met retrieved
	*/
	static fetchMetsSuccess = mets => ({
		type: FETCH_METS_SUCCESS,
		payload: mets
	});

	/*
	* action creator to indicate met not retrieved
	*/
	static fetchMetsFailure = error => ({
		type: FETCH_METS_FAILURE,
		payload: { error }
	});

	/**
	 * Lambda generator that creates a function that takes dispatch and fetches simulation and scenario mets,
	 * using the dispatch to update the redux store as appropriate during the process. Can be treated as an
	 * action creator by passing this fetchMets to dispatch. 
	 * 
	 * @param {Number} scenarioId the id of the scenario that the returned function is to fetch met data for
	 * @returns A function that takes dispatch and fetches simulation and scenario mets and met configs,
	 * using the dispatch to update the redux store as appropriate during the process.
	 */
	static fetchMets(scenarioId) {

		return dispatch => {
			dispatch(this.fetchMetsBegin());
			return Requests.get(`/met/scenario/${scenarioId}`)
				.then(json => {
					const metProfiles = json.metProfiles;
					// Loop through met and parse geometry string to json
					for (const metProfile of metProfiles) {
						if (metProfile.geometry) {
							metProfile.geometry = metProfile.geometry;
							metProfile.geoJson = { geometry: metProfile.geometry, properties: {}, type: "Feature" };
						}
						metProfile.scenarioId = scenarioId
						metProfile.time = new Date(metProfile.time)
						metProfile.metType = "profile"; // Create tag for marker display type
					}
					json.metProfiles = metProfiles;
					dispatch(this.fetchMetsSuccess(json));
					return json;
				})
				.catch(error => {
					console.error(error);
					dispatch(this.fetchMetsFailure(error))
				});
		};
	}

}