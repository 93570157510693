import {
  FETCH_SUCCESS,
  FETCH_BEGIN,
  FETCH_FAILURE,
  CLEAR
} from "./actions.jsx";

export class AuditsReducer {

  static auditsInfo(state, action) {
    const initialState = {
      audits: [],
      lastFetchTime: null,
      error: null,
      loading: false
    };

    switch (action.type) {
      case CLEAR:
        return initialState;
      case FETCH_SUCCESS:
        const lastFetchTime = action.payload.fetchTime.toISOString();
        // If the fetch pulled nothing - skip the sorting/filtering logic
        if (action.payload.audits.length === 0) {
          return Object.assign({}, state, {
            loading: false,
            lastFetchTime: lastFetchTime
          });
        }
        // The audits being fetched are from last time of successful fetch, so concatenate them with existing
        let audits = state.audits.concat(action.payload.audits);
        // Sort audits by time
        audits = audits.sort(function (a, b) {
          var dateA = new Date(a.time).getTime();
          var dateB = new Date(b.time).getTime();
          if (dateA === dateB) {
            return a.id < b.id ? 1 : -1;
          }
          return dateA < dateB ? 1 : -1;
        });
        // Fitler out duplicate entries (The polling might pull already existing audits)
        const ids = audits.map(o => o.id);
        const uniqueAudits = audits.filter(({ id }, index) => !ids.includes(id, index + 1));
        return Object.assign({}, state, {
          loading: false,
          lastFetchTime: lastFetchTime,
          audits: uniqueAudits,
        });
      case FETCH_BEGIN:
        return Object.assign({}, state, {
          loading: true,
          error: null,
        });
      case FETCH_FAILURE:
        return Object.assign({}, state, {
          loading: false,
          error: action.payload.error.message,
        });
      default:
        return state || initialState;
    }
  }
}
