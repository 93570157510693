const MODELS_THAT_REQUIRE_MET_DATA = ["UDM", "ATP45"];

export class IncidentsUtils {

    /**
     * Gets an array of the names of models required to simulate the given incidents
     * @param {Array[Object]} incidents array of incident objects from the store 
     * @returns {Array[String]} an array of names of the unique models required to run all of the given incidents
     */
    static getRequiredModels(incidents) {
        const requiredModels = [];
        incidents.forEach(incident => {
            switch (incident.incidentType) {
                // Basic incidents have the types below and use UDM
                case "POINT":
                case "LINE":
                case "MOVING":
                case "LIQUID_POOL":
                    requiredModels.push("UDM");
                    break;
                // Non-basic incidents will be defined by a template in the UI-Server that includes a field to specify the required models
                default:
                    requiredModels.push(...incident.requiredModels);
            }
        })
        return [...new Set(requiredModels)];
    }

    /**
     * Checks whether the given incidents include dispersive aspects
     * @param {Array[Object]} incidents array of incident objects from the store 
     * @returns {Boolean} true if any of the given incidents include a dispersive aspect
     */
    static includesDispersion(incidents) {
        const requiredModelTypes = IncidentsUtils.getRequiredModels(incidents);
        if (requiredModelTypes.includes("UDM")) {
            return true;
        }
        return false;
    }

    /**
     * Checks whether the given incidents require meteorological data in order to be simulated
     * @param {Array[Object]} incidents array of incident objects from the store 
     * @returns {Boolean} true if any of the given incidents require a model that requires meteorological data to run
     */
    static requiresMetData(incidents) {
        const requiredModelTypes = IncidentsUtils.getRequiredModels(incidents);
        for (const modelName of MODELS_THAT_REQUIRE_MET_DATA) {
            if (requiredModelTypes.includes(modelName)) {
                return true;
            }
        }
        return false;
    }

    /**
     * Creates an array of material options that can be given to a dropdown UI component
     * Appends unNumber to the material display name if present, and shows all
     * synonyms on hover of options as a tooltip
     * @param {Array[Object]} materials the array of materials provided by IncidentServices
     * @returns {Array[Object]} The given materials as options for a dropdown 
     */
    static createMaterialsDropdownOptions(materials) {
        return materials.map(material => {
            let tooltip = material.name;
            if (material.materialSynonyms.length > 0) {
                tooltip += `, ${material.materialSynonyms.join(", ")}`;
            }
            
            let displayName = material.name;
            if (material.unNumber) {
                displayName += ` [${material.unNumber}]`;
                tooltip += `, ${material.unNumber}`;
            }

            return {
                key: material.id,
                text: displayName,
                value: material.id,
                materialSynonyms: material.materialSynonyms,
                title: tooltip
            }
        })
    }

    /**
     * A search function override for the material search dropdown input field
     * @param {Array} options material dropdown options
     * @param {String} searchString material search string
     * @returns list of options matching the search string
     */
    static searchThroughMaterials(options, searchString) {
        // Return options where either text or synonyms match the searchString, all lowercased
        return options.filter(o => {
            return o.text.toLowerCase().includes(searchString.toLowerCase())
                || o.materialSynonyms.some(s => s.toLowerCase().includes(searchString.toLowerCase()));
        });
    }
}