import { FETCH_METS_BEGIN, FETCH_METS_SUCCESS, FETCH_METS_FAILURE } from '../common/actions.jsx';
import { PRESET_MET_PROFILE_TYPE, CREATE_MET_PRESET, EDIT_MET_PRESET, CANCEL_EDIT_MET_PRESET, UPDATE_MET_PRESET, REMOVE_MET_PRESET, CLEAR_MET_PRESETS } from './actions.jsx';

const INITIAL_STATE = {
    metProfiles: {},
    loading: false,
    setting: false,
    editing: false, // this will be false, null, or a met object in the live state
    error: null
}

/**
 * Reducer to handle met actions and global state for preset mets
 */
export const metPresetsReducer = (state, action) => {
    switch (action.type) {      
        case FETCH_METS_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            }
        case FETCH_METS_SUCCESS:
            // Create a dict of the relevant profiles, keyed by profile id
            const metProfiles = Object.fromEntries(action.payload.metProfiles
                .filter(profile => profile.metProfileType === PRESET_MET_PROFILE_TYPE)
                .map(profile => [profile.id, profile])
            );
            return {
                ...state,
                loading: false,
                editing: null,
                metProfiles: metProfiles
            }
        case FETCH_METS_FAILURE:
            return {
                ...state,
                loading: false,
                editing: null,
                error: action.payload.error
            }
        case CREATE_MET_PRESET:
            return {
                ...state,
                editing: action.payload
            }
        case EDIT_MET_PRESET:return {
                ...state,
                editing: state.metProfiles[action.payload]
            }
        case CANCEL_EDIT_MET_PRESET:
            return {
                ...state,
                editing: null
            }
        case UPDATE_MET_PRESET:
            return {
                ...state,
                editing: null,
                metProfiles: {
                    ...state.metProfiles,
                    [action.payload.id]: action.payload
                }
            }
        case REMOVE_MET_PRESET:
            const newState = {...state}
            delete newState.metProfiles[action.payload]
            return newState
        case CLEAR_MET_PRESETS:
            return INITIAL_STATE
        default:
            return state || INITIAL_STATE;
    }
}