export class MapUtils {

    /**
     * Removes on click listener from the map for output layer
     * @param {Object} map - leaflet map
     */
    static removeMapClickForOutputLayer(map) {
        map.eachLayer(layer => {
            if (layer.id === "simulation_output_layer" && layer.removeMapClickListener) {
                layer.removeMapClickListener();
            }
        })
    }

    /**
     * Adds on click listener from the map for output layer
     * @param {Object} map - leaflet map
     */
    static addMapClickForOutputLayer(map) {
        map.eachLayer(layer => {
            if (layer.id === "simulation_output_layer" && layer.addMapClickListener && layer._visible) {
                layer.addMapClickListener();
            }
        })
    }

    /**
     * Creates a building info static popup HTML string
     * @param {Function} t translation function 
     * @param {JSON} buildInfo building information in a form of JSON
     */
    static getBuildingPopupString(t, buildInfo) {

        // Open all the relevant popup tags
        let popupString = '<div style="padding: 10px;">' +
            `<h2 style="color: #f7af09;">${t("popups.building.header")}</h2>` +
            '<ol style="list-style: none; font-size: 14px; line-height: 24px; padding-inline-start: 10px; font-weight: bold;">';

        // Add all the relevant building information as list items to the popup
        // Get height property (either 'height m' or 'mean_hght', depending on the source)
        let buildHeight = buildInfo['height m'];
        if(!buildHeight) {
            buildHeight = buildInfo['mean_hght'];
        }
        popupString += `<li style="clear: both;">${t("popups.building.heightM")}: <em>${buildHeight}</em></li>`;
        if (buildInfo['area (m2)']) {
            popupString += `<li style="clear: both;">${t("popups.building.areaM2")}: <em>${buildInfo['area (m2)']}</em></li>`;
        }
        if (buildInfo['landcover']) {
            popupString += `<li style="clear: both;">${t("popups.building.landCover")}: <em>${buildInfo['landcover']}</em></li>`;
        }
        if (buildInfo['total pop']) {
            popupString += `<li style="clear: both;">${t("population.population")} (Day)</li>` +
                `<ul><li style="clear: both;">Total: <em>${buildInfo['total pop']}</em></li>` +
                `<li style="clear: both;">Age < 15: <em>${buildInfo['<15']}</em></li>` +
                `<li style="clear: both;">Age 15 - 65: <em>${buildInfo['15 - 65']}</em></li>` +
                `<li style="clear: both;">Age > 65: <em>${buildInfo['>65']}</em></li></ul>`
        }
        if (buildInfo['pop night']) {
            popupString += `<li style="clear: both;">${t("population.population")} (Night)</li>` +
                `<ul><li style="clear: both;">Total: <em>${buildInfo['pop night']}</em></li>` +
                `<li style="clear: both;">Age < 15: <em>${buildInfo['<15 night']}</em></li>` +
                `<li style="clear: both;">Age 15 - 65: <em>${buildInfo['15 - 65 night']}</em></li>` +
                `<li style="clear: both;">Age > 65: <em>${buildInfo['>65 night']}</em></li></ul>`
        }

        return popupString;
    }

}