import React from 'react';
import L from 'leaflet';
import { LayerGroup, LayersControl } from 'react-leaflet';
import WMSFeatureLayer from '../WMSFeatureLayer';
import { MapUtils } from '../../utils/MapUtils.jsx';

const { Overlay, } = LayersControl

export class BuildingLayers {

    static getMapLayers(t) {
        let geoserverUrl = window.env.GEO_DATA_URL + '/geoserver/geo-data/wms';
        return (
            <Overlay name={t("map.buildingFootprints")}>
                <LayerGroup>
                <WMSFeatureLayer
                    key="building_footprints"
                    layer="building_footprints"
                    options={{
                        "format": "image/png",
                        "transparent": "true",
                        "info_format": "application/json",
                        'maxZoom': 21
                    }}
                    url={geoserverUrl}
                    getPopupString={MapUtils.getBuildingPopupString}
                />
                </LayerGroup>
            </Overlay>
        );
    }
}