import React from "react";
import { withTranslation } from "react-i18next";
import { Accordion } from "semantic-ui-react";
import { AuditUtils } from "./auditUtils";
import "./styles.scss";

class AuditAccordion extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      audits: props.audits,
      activeIndex: null,
    };
  }

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  }


  getContent = () => {
    var { t } = this.props;
    let auditCards = [];
    for (let ind in this.state.audits) {
      let audit = this.state.audits[ind];
      auditCards.push(AuditUtils.getAuditDescription(audit, t, this.state.activeIndex, this.handleClick));
    }
    return auditCards;
  }

  render() {
    return (
      <Accordion className="audit-accordion">
        {this.getContent()}
      </Accordion>
    );
  }
}


export default withTranslation()(AuditAccordion);
