import { withLeaflet, Marker } from "react-leaflet";
import '../../../js/DumbMGRS/L.DumbMGRS.scss';
import {
    generateGZDGrids, generate100kGrids, generate1000meterGrids
} from '../../../js/DumbMGRS/L.DumbMGRS';
import { Component } from "react";

export const MGRS_GRID_NAME = "MGRS Grid"; 

/**
 * MGRS Grid overlay for the leaflet map
 * It uses custom JS leaflet layer DumbMGRS (https://github.com/pistell/Leaflet.DumbMGRS which has some changes made)
 * And programatically displays/hides this layer based on overlayadd/overlayremove events
 */
class MGRSGrid extends Component {

    constructor(props) {
        super(props);
        // Instantiate 3 available precision grid layers provided by the DumbMGRS class
        this.generateGZDGrids = generateGZDGrids;
        this.generate100kGrids = generate100kGrids;
        this.generate1000meterGrids = generate1000meterGrids;
    }

    createLeafletElement(props) {
        // withLeaflet expects this to be explicitly defined
    }

    componentDidMount() {
        // Once the component mounts, add event llisteners to overlay added/removed
        this.props.leaflet.map.on('overlayadd', this.addOverlay);
        this.props.leaflet.map.on('overlayremove', this.removeOverlay);

        // Add the MGRS grid layers to the map
        this.generateGZDGrids.addTo(this.props.leaflet.map);
        this.generate100kGrids.addTo(this.props.leaflet.map);
        this.generate1000meterGrids.addTo(this.props.leaflet.map);

        // Hide the MGRS layers, as the overlay is not checked in by default
        this.generateGZDGrids.hideGrids();
        this.generate100kGrids.hideGrids();
        this.generate1000meterGrids.hideGrids();
    }

    addOverlay = (e) => {
        // Once an overlay is added to leaflet map, check the overlay name to be MGRS
        // If it matches, programatically display MGRS grids, by calling showGrids()
        if(e.name === MGRS_GRID_NAME) {
            this.generateGZDGrids.showGrids();
            this.generate100kGrids.showGrids();
            this.generate1000meterGrids.showGrids();
        }
    }

    removeOverlay = (e) => {
        // Once an overlay is removed from leaflet map, check the overlay name to be MGRS
        // If it matches, programatically hide MGRS grids, by calling hideGrids()
        if(e.name === MGRS_GRID_NAME) {
            this.generateGZDGrids.hideGrids();
            this.generate100kGrids.hideGrids();
            this.generate1000meterGrids.hideGrids();
        }
    }

    render() {
        // This is a workaround/hack, as leaflet will not render the overlay in the layers control
        // if it does not have anything to render
        // So as a workaround, render a marker in a normally non visible location on the map
        // But also set its opacity to 0, so it's not visible
        return <Marker opacity='0' position={[900,900]}/>
    }

}

export default withLeaflet(MGRSGrid);