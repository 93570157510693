import React from 'react';
import { LayerGroup, WMSTileLayer, LayersControl } from 'react-leaflet';
import LandUseLegend from '../legends/LandUseLegend.jsx';

const { Overlay, } = LayersControl
const GEOSERVER_LANDCOVER_LAYER_NAME = 'landcover'

export class LandCoverLayers {

    static getMapLayers(t) {
        // UA Geoserver URL
        const url = window.env.GEO_DATA_URL + '/geoserver/geo-data/wms';

        return (
            <Overlay name={t("map.landCover")}>
                <LayerGroup id="landCoverLayer" >
                    <WMSTileLayer
                        url={url}
                        layers={GEOSERVER_LANDCOVER_LAYER_NAME}
                        format='image/png'
                        transparent='true'
                        opacity="0.7"
                    />
                </LayerGroup>
            </Overlay>
        )
    }

    static getLegend(t) {
        return <LandUseLegend layerTitle={t("map.landCover")} legendTrans={t("legendsTool.legend")} />
    }
}