/**
 * Enum for items in the sidebar
 */
const SidebarItemsEnum = {
    SETUP: 'setup',
    AOIS: 'aois',
    MET_PRESETS: 'metPresets',
    SIMULATION: 'simulation',
    INCIDENTS: 'incidents',
    MET: 'met',
    SIMULATION_CONFIG: 'simulationConfig',
    RUN_SIMULATION: 'runSimulation'
};

export default SidebarItemsEnum;
