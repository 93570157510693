import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { Icon } from "semantic-ui-react";
import { Actions as Actions } from "../../../services/user/actions";


class UserHeaderButton extends React.Component {

    render() {
        const { t } = this.props;

        return (
            <>
                <button
                    className="navigation-button"
                    onClick={(e) => this.props.handleItemClick(e, this.props.name)}
                >
                    <Icon name="user outline" />
                    {t('header.user')}
                </button>
            </>
        );
    }
}

/*
 * Maps state from the store to properties used by this class
 */
const mapStateToProps = (store, props) => {
    return {
        userState: store.userState,
        ...props,
    };
};

/*
 * Maps properties to dispatch methods to send actions to the store reducers
 */
const mapDispatchToProps = (dispatch) => {
    return {
        setVerified: (verified) => {
            dispatch(Actions.setVerified(verified));
        }
    };
};
export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(UserHeaderButton);
