import React from "react";
import { Dropdown, Form, Input } from "semantic-ui-react";

/**
 * Small functional component to display input with hard-coded unit.
 */
const InputFieldWithStaticUnit = ({ label, updateFunc, value, unit, fieldErrors }) => {
    return (
        <Form.Field inline
            error={fieldErrors}>
            <label>{label}</label>
            <Input
                label={
                    <Dropdown
                        className="units-dropdown"
                        disabled={true}
                        text={<p>{unit.unitName}<sup>{unit.exponent}</sup></p>}
                    />
                }
                className={"input-bar"}
                labelPosition="right"
                value={value}
                onChange={(data) => updateFunc(data)}
            />
            {fieldErrors ? <div className="ui pointing above prompt label">
                {fieldErrors}
            </div> : null}
        </Form.Field>
    )
}

export default InputFieldWithStaticUnit;
