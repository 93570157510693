import React, { Component } from 'react'
import { withTranslation } from "react-i18next";
import { Confirm } from 'semantic-ui-react'

class ConfirmExampleConfirm extends Component {

    state = { open: false }

    componentDidMount() {

        // Check the mobile version is enabled
        if (window.env.REACT_APP_MOBILE_ENABLED === 'true') {
            // Check the user is using a mobile device and ask them if they
            // want to proceed to a mobile PWA instead
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                // true for mobile device
                this.setState({ open: true });
            }
        }
    }

    open = () => this.setState({ open: true })

    close = () => this.setState({ open: false })

    confirm = () => {
        // redirect to the mobile URL
        window.open(window.env.REACT_APP_MOBILE_URL);
    }

    render() {
        const t = this.props.t;


        return (
            <div>
                <Confirm
                    open={this.state.open}
                    content={t('messages.mobileDeviceDetected')}
                    onCancel={this.close}
                    onConfirm={this.confirm}
                />
            </div>
        )
    }
}

export default withTranslation()(ConfirmExampleConfirm);