import React from "react";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import { List } from "semantic-ui-react";
import i18n from '../../i18n';
import "./styles.scss";
import UrbanAwareLogo from "../../logo2.png";
import SmithsLogo from "../../images/smiths-cobranded-logo.png";

const SHOW_HEADER = window.env.REACT_APP_SHOW_HEADER === 'true';
const SMITHS_REQUESTS = window.env.REACT_APP_SMITHS_REQUESTS === 'true';

class Footer extends React.Component {

    changeLanguage = (e) => {
        console.log("change language", e.target.value);
        i18n.changeLanguage(e.target.value);
    }

    displayTestLanguage = () => {
        if (process.env.NODE_ENV === 'development') {
            return <option value="test">Test</option>
        }
    }

    render() {
        var { t } = this.props;

        const language = <div className="language">
                            <select className="language-selector" name="language" id="lang" onChange={this.changeLanguage}>
                                <option value="en">English</option>
                                <option value="cs">Čeština</option>
                                <option value="de">Deutsch</option>
                                <option value="es">Español</option>
                                <option value="jp">日本語</option>
                                <option value="pl">Polski</option>
                                <option value="uk">Українська</option>
                                {this.displayTestLanguage()}
                            </select>
                        </div>

        const links = <div className="language">
                        <List horizontal className="links">
                            {/*<List.Item>
                                {t("footer.terms")}
                            </List.Item>

                            <List.Item>
                                {t("footer.cookie")}
                            </List.Item>

                            <List.Item>
                                {t("footer.privacy")}
                            </List.Item>

                            <List.Item>
                                {t("footer.accessibility")}
                            </List.Item>*/}

                            <List.Item className="touchable" onClick={() => this.props.showModal('Data Licences', '/data-licences')}>
                                {t("footer.data")}
                            </List.Item>
                        </List>
                    </div>

        let middle = {}
        let right = {}
        let left = {}
        if (!SMITHS_REQUESTS) {
            left = <div className="language-and-links">
                { language }
                { links }
            </div>
            middle = <div className="copyright">
                    <span>{'\u00A9'} {t("footer.rights")}</span>
                    <span>{window.env.REACT_APP_FOOTER_VERSION}</span>
            </div>
            right = <img src={SmithsLogo} alt={t("header.uaLogo")} className="footer-logo" /> 
        } else if (SHOW_HEADER) {
            left = language
            middle = <div className="copyright">
                <span>{'\u00A9'} {t("footer.rights")} - {window.env.REACT_APP_FOOTER_VERSION}</span>
            </div>
            right =  links 
        } else {
            // Insert logo
            left = <img src={UrbanAwareLogo} alt={t("header.uaLogo")} className="footer-logo" />
            middle = <div/>
            right = <div/>
        }

        return (
            <div className="footer">
                <div className="footer-left">
                    { left }
                </div>
                <div className="footer-middle">
                    { middle }
                </div>
                <div className="footer-right">
                    { right }
                </div>
            </div>
        );
    }
}

export default compose(withTranslation())(Footer);
