export class Unit {
    constructor(unitName, exponent) {
        this.unitName = unitName;
        this.exponent = exponent;
    }

    getDisplayName(){
        return this.unitName + "^" + this.exponent
    }
}

export class UnitsHelper {
    static unitsMap = new Map([
        ["CONCENTRATION", new Unit("kg m", -3)],
        ["DEPOSITION", new Unit("kg m", -2)],
        ["DOSAGE", new Unit("kg s m", -3)],
        ["ECT50", new Unit("", 0)],
        ["ICT50", new Unit("", 0)],
        ["LCT50", new Unit("", 0)],
        ["AEGL", new Unit("", 0)],
        ["INDOOR_CONCENTRATION", new Unit("kg m", -2)],
        ["INDOOR_DOSAGE", new Unit("kg s m", -3 )],
        ["BLAST", new Unit("psi", "")] ,
        ["ATP-45", new Unit("", "")] 
    ]);

    /**
     * Returns the definition of a given unit
     * 
     * @param {*} unitType 
     * @returns Unit
     */
    static getUnits(unitType) {

        if(unitType === "" || unitType === undefined){
            console.log("Trying to get empty unit.")
            return new Unit("", "");
        }
        const unit = this.unitsMap.get(unitType);

        if(unit === undefined || unit.unitName === ""){
            return new Unit("", "");
        } else {
            return unit;
        }
    }
}