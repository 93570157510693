import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { Actions } from "../../services/user/actions";
import "../header/appHeader.scss";
import Logo from '../header/ua-logo.png';
import "./styles.scss";

class EndUserAgreement extends React.Component {

    render() {
        /**
         * Render the EULA warning
         */

        const { t } = this.props;
        return (
            <div className={"user-agreement-panel"}>
                <div className="user-agreement-title-panel">
                    {t("user-agreement.title")}
                    <img src={Logo} alt="UrbanAware logo" />
                </div>
                <div className="user-agreement-content">
                    <div className={"user-agreement-body"}>
                        {t("user-agreement.agreement")}
                        <a style={{cursor: "pointer"}} onClick={() => this.props.showModal('EULA', '/eula')}>
                            {t("user-agreement.agreementPt2")}
                        </a>
                    </div>
                    <div className={"user-agreement-body"}>
                        {t("user-agreement.agreementPt3")}
                    </div>
                    <button className="login-button" onClick={this.props.acceptEULA}>
                        {t("user-agreement.accept")}
                    </button>
                    <button className="register" onClick={this.props.declineEULA}>
                        {t("user-agreement.decline")}
                    </button>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setUser: (user) => {
            dispatch(Actions.setUser(user));
        },
    };
};

export default compose(withTranslation(), connect(null, mapDispatchToProps))(EndUserAgreement);
