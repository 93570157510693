import { Index, TimeRange, TimeSeries } from "pondjs";
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
    BarChart, ChartContainer, ChartRow, Charts, Legend, Resizable, styler, YAxis
} from "react-timeseries-charts";
import { compose } from 'redux';
import '../map.scss';

const MS_PER_MINUTE = 60000;
const labelStyle = {
    normal: {
        fontSize: 'normal', color: 'white', cursor: 'pointer', padding: '5px', margin: '0px'
    },
    highlighted: { fontSize: 'normal', color: 'white', cursor: 'pointer' },
    selected: { fontSize: 'normal', color: 'white', cursor: 'pointer' },
    muted: { fontSize: 'normal', color: 'white', opacity: 0.4, cursor: 'pointer' },
}
const valueStyle = {
    normal: { fontSize: 'normal', color: '#FFF', cursor: 'pointer' },
    highlighted: { fontSize: 'normal', color: '#222', cursor: 'pointer' },
    selected: { fontSize: 'normal', color: '#333', cursor: 'pointer' },
    muted: { fontSize: 'normal', color: '#333', opacity: 0.4, cursor: 'pointer' }
}

class BarConcentrationChart extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            tracker: null,
            legendValues: null,
            trackerX: null,
            timeSeries: [],
            bars: [],
            categories: [],
            legendStyle: {},
            xDomain: new TimeRange(0, 0)
        };
    }

    componentDidMount() {
        this.makeBars();
    };

    componentDidUpdate(prevProps) {
        if (prevProps.readingsByMaterial !== this.props.readingsByMaterial) {
            this.makeBars();
        }
    }

    handleTrackerChanged = (t, scale) => {
        if (t) {
            let legendValues = [];
            for (let i in this.state.timeSeries) {
                let series = this.state.timeSeries[i];
                if (series.materialName === "") {
                    // Dont display blank values of 0
                    continue;
                }
                let valueAtCursor = series.series.atTime(t);

                if (valueAtCursor.index().asTimerange().contains(t)) {
                    let value = valueAtCursor.get(series.materialName);
                    legendValues.push(this.valueFormat(series.materialName, value));
                }
            }

            this.setState({
                tracker: t,
                legendValues: legendValues,
                trackerX: t && scale(t)
            });
        }
    };

    valueFormat = (material, value) => {
        return <div key={material + value}>{material}: {value}<br /></div>;
    }

    timeLabelFormat = (date) => {
        return "Time: " + this.timeFormat(date);
    };

    timeFormat = (date) => {
        return date.toLocaleTimeString('en-GB', {
            hour: "numeric",
            minute: "numeric"
        });
    };

    makeBars = () => {
        const bars = [];
        let categories = [];
        const legendStyle = {};
        let timeSeries = [];

        let readingsByMaterial = this.props.readingsByMaterial;

        let begin = Date.now();
        let end = 0;

        // for each material make a new series and legend
        for (let materialName in readingsByMaterial) {

            let materialReadings = readingsByMaterial[materialName];
            let points = materialReadings.map((m) => [
                Index.getIndexString("1m", new Date(m.x)),
                m.y
            ]);

            // Create time series
            const series = new TimeSeries({
                name: materialName,
                columns: ["index", materialName],
                points: points
            });
            timeSeries.push({ materialName, series });

            // Create style for bar and legend
            let colour = this.props.intToRGB(materialName);
            const style =
            {
                key: materialName,
                stroke: colour,
                fill: colour,
                opacity: 1.0,
                color: colour
            };

            // Add bar chart for material
            bars.push({
                materialName: materialName,
                style: style,
                series: series
            });

            // Update time range
            begin = series.begin() < begin ? series.begin() : begin;
            end = series.end() > end ? series.end() : end;

            // For non-blank materials, create a legend
            if (materialName === "") {
                continue;
            }
            legendStyle[materialName] = {
                symbol: {
                    normal: style
                },
                label: labelStyle,
                value: valueStyle
            }
            categories.push(
                {
                    key: materialName,
                    label: materialName
                }
            );
        }

        // Adjust end time to pad out
        end = new Date(end.valueOf() + 5 * MS_PER_MINUTE);

        if (end.valueOf() - begin.valueOf() < 60 * MS_PER_MINUTE) {
            begin = new Date(begin.valueOf() - 30 * MS_PER_MINUTE);
        }
        let xDomain = new TimeRange(begin, end);

        this.setState({ timeSeries: timeSeries, bars: bars, categories: categories, legendStyle: legendStyle, xDomain: xDomain })
    };

    render() {
        const { t } = this.props;

        const markerStyle = {
            color: "#AAA",
            padding: "6px",
            background: "rgba(0,0,0,1.0)",
            border: "solid",
            borderWidth: "1px 1px 1px 1px",
            borderColor: "lightgray",
            borderRadius: "5px"
        };

        // Create bar component for graph
        let barCharts = [];
        for (let i in this.state.bars) {
            let bar = this.state.bars[i];
            barCharts.push(
                <BarChart
                    key={"chart-" + bar.materialName}
                    axis="bar"
                    style={styler([bar.style])}
                    spacing={1}
                    columns={[bar.materialName]}
                    series={bar.series}
                    minBarHeight={1}
                />
            );
        }

        return (
            <div className="chart-div">
                <div>
                    <Legend
                        type="swatch"
                        marginBottom="5px"
                        categories={this.state.categories}
                        style={this.state.legendStyle}
                    />
                    {this.state.tracker ? (
                        <div style={{ position: "relative" }}>
                            <div style={{ position: "absolute", left: this.state.trackerX }}>
                                <div style={markerStyle}>
                                    {this.timeLabelFormat(this.state.tracker)}
                                    <br />
                                    {this.state.legendValues}
                                </div>
                            </div>
                        </div>
                    ) : null}

                    <Resizable>
                        <ChartContainer
                            padding={15}
                            timeRange={this.state.xDomain}
                            width={650}
                            format={this.timeFormat}
                            trackerPosition={this.state.tracker}
                            onTrackerChanged={this.handleTrackerChanged}
                        >
                            <ChartRow height="150"
                            >
                                <YAxis
                                    id="bar"
                                    label={t('sensor.chart.bar')}
                                    min={0}
                                    max={8}
                                    format="d"
                                    width="50"
                                    type="linear"
                                />
                                <Charts>
                                    {barCharts}
                                </Charts>
                            </ChartRow>
                        </ChartContainer>
                    </Resizable>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (store) => {
    return {
        mapState: store.mapState
    }
}

export default compose(withTranslation(), connect(mapStateToProps, null))(BarConcentrationChart);