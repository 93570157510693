/*
* action types
*/
export const SHOW_KEY_BUILD_POPUP = 'SHOW_KEY_BUILD_POPUP';
export const SHOW_SENSORS_POPUP = 'SHOW_SENSORS_POPUP';
export const SHOW_OUTPUT_PARAMS_POPUP = 'SHOW_OUTPUT_PARAMS_POPUP';
export const HIDE_POPUP = 'HIDE_POPUP';

export class Actions {

    /*
     * action creators
     */
    static showKeyBuildingPopup(id) {
        return {
            type: SHOW_KEY_BUILD_POPUP,
            payload: { id }
        }
    }

    static showSensorsPopup(sensorData) {
        return {
            type: SHOW_SENSORS_POPUP,
            payload: { sensorData }
        }
    }

    static showOutputPopup(outputParams) {
        return {
            type: SHOW_OUTPUT_PARAMS_POPUP,
            payload: { outputParams }
        }
    }

    static hidePopup() {
        return {
            type: HIDE_POPUP
        }
    }
}