/*
    ./client/components/App.jsx
*/
import React from "react";
import { Menu, Popup } from "semantic-ui-react";
import "whatwg-fetch";
import {ReactComponent as MetIcon} from '../../../images/Meteorology.svg';
import MetPresetsPanel from "./metPresetsPanel";

export default class MetPresetsMenu extends React.Component {

  render() {
    return <>
      <Popup
        className="popup"
        mouseEnterDelay={300}
        trigger={
          <Menu.Item
            className="icon-container"
            name={this.props.name}
            active={this.props.activeItem === this.props.name}
            onClick={this.props.onClick}
            disabled={this.props.disabled}
          >
            <MetIcon className="sidebar-icon" />
          </Menu.Item>
        }
        position={"right center"}
      >
        {this.props.title}
      </Popup>
      <div className="menu-option">
        {this.props.activeItem === this.props.name ?
          <MetPresetsPanel cancel={this.props.cancel} />
          : null
        }
      </div>
    </>
  }
}
