import auth from '@platform/platform-auth-sdk';

class SmithsRequests {

    constructor() {
        console.log("Initiate Smiths auth sdk client: ", window.env.SMITHS_CLIENT_ID, " and resource: ", window.env.SMITHS_RESOURCE_ID);
        // Initiate the auth class
        auth.init({
            clientId: window.env.SMITHS_CLIENT_ID,
            endpoints: {
                '/accounts/.*': window.env.SMITHS_RESOURCE_ID,
                '/users/.*': window.env.SMITHS_RESOURCE_ID,
                '/scenario/.*': window.env.SMITHS_RESOURCE_ID,
                '/simulation/.*': window.env.SMITHS_RESOURCE_ID,
                '/met/.*': window.env.SMITHS_RESOURCE_ID,
                '/user-guide/': window.env.SMITHS_RESOURCE_ID,
                '/population/.*': window.env.SMITHS_RESOURCE_ID,
                '/buildings/.*': window.env.SMITHS_RESOURCE_ID,
                '/incident/.*': window.env.SMITHS_RESOURCE_ID,
                '/contours/.*': window.env.SMITHS_RESOURCE_ID,
                '/material/.*': window.env.SMITHS_RESOURCE_ID,
                '/output/.*': window.env.SMITHS_RESOURCE_ID,
                '/aois/.*': window.env.SMITHS_RESOURCE_ID,
                '/audits/.*': window.env.SMITHS_RESOURCE_ID,
                '/legends/.*': window.env.SMITHS_RESOURCE_ID,
                '/bvim/.*': window.env.SMITHS_RESOURCE_ID,
                '/legends/.*': window.env.SMITHS_RESOURCE_ID,
                '/sensors/.*': window.env.SMITHS_RESOURCE_ID
            }
        })
    }

    get = async (url, isBlob) => {
        return auth.http.get(url, { responseType: isBlob ? 'blob' : 'json' })
            .toPromise()
            .then((data) => {
                console.log("Data", data);
                return isBlob ? new Blob([data]) : data;
            })
            .catch((err) => {
                console.log("Error", err);
                throw err;
            });
    };

    post = async (url, body) => {
        return auth.http.post(url, body)
            .toPromise()
            .then((data) => {
                console.log("Data", data);
                return data;
            })
            .catch((err) => {
                console.log("Error", err);
                throw err;
            });
    };

    patch = async (url, body) => {
        return auth.http.patch(url, body)
            .toPromise()
            .then((data) => {
                console.log("Data", data);
                return data;
            })
            .catch((err) => {
                console.log("Error", err);
                throw err;
            });
    };

    put = async (url, body) => {
        return auth.http.put(url, body)
            .toPromise()
            .then((data) => {
                console.log("Data", data);
                return data;
            })
            .catch((err) => {
                console.log("Error", err);
                throw err;
            });
    };

    deleteRequest = async (url) => {
        return auth.http.delete(url)
            .toPromise()
            .then((data) => {
                console.log("Data", data);
                return data;
            })
            .catch((err) => {
                console.log("Error", err);
                throw err;
            });
    };

}

export default SmithsRequests;