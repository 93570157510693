/*
    ./client/components/App.jsx
*/
import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { Menu, Popup } from "semantic-ui-react";
import { ReactComponent as OutputIcon } from "../../../../images/Outputs.svg";
import { ResultsActions } from "../../../../services/results/actions.jsx";
import PanelWrapper from "../../../common/panelWrapper.jsx";
import SimulationFilterMenu from "./simulationFilterMenu";

/**
 * Button to control whether the met displaying/editing views are visible
 */
class OutputMenu extends React.Component {

    componentDidUpdate = (prevProps, prevState) => {

        const currentSim = this.props.simulationState.selectedSimulation;
        const prevSim = prevProps.simulationState.selectedSimulation;
        // Boolean to check whether previous simulation was null and current one is not
        const hasSimulationChangedFromNoneToSelected = !prevSim && currentSim;
        // Check whether a selected simulation has changed
        const hasSelectedSimulationChanged =
            prevSim && currentSim && prevSim.id !== currentSim.id;

        const simulationTheSame = prevSim && currentSim && prevSim.id === currentSim.id;

        // Check the data types available have switched from being empty to not empty
        // This is due to live update while simulation is running, the initial data type received might still be empty
        let dataTypesLengthDiffers = false;
        if (simulationTheSame) {

            const currentDataTypes = this.props.resultsState.dataTypes[
                this.props.simulationState.selectedSimulation.id
            ];

            const prevDataTypes = prevProps.resultsState.dataTypes[
                this.props.simulationState.selectedSimulation.id
            ];

            // Either previous data types is an empty array and current has data OR
            // previous data types undefined and current have data
            dataTypesLengthDiffers = (currentDataTypes && prevDataTypes
                && prevDataTypes.length === 0 && currentDataTypes.length > 0) ||
                (currentDataTypes && !prevDataTypes && currentDataTypes.length > 0);
        }

        if (
            hasSimulationChangedFromNoneToSelected ||
            hasSelectedSimulationChanged ||
            dataTypesLengthDiffers
        ) {
            this.selectPriorityContourType();
        }

        // If the selected simulation has changed - update the selected materials
        if (hasSelectedSimulationChanged) {
            this.updateSelectedMaterials();
        }
    };

    updateSelectedMaterials = () => {
        const selectedMaterialIds = this.props.resultsState.selectedMaterials;
        const currentResultsMaterials = this.props.resultsState.materials[
            this.props.simulationState.selectedSimulation.id
        ];
        if (currentResultsMaterials) {
            // Collect material IDs from the results for current simulation
            const currentMaterialIds = currentResultsMaterials.map(({ id }) => id);

            // Gather common material IDs between selected materials and those available for the selected simulation
            let commonMaterials = selectedMaterialIds.filter(m => currentMaterialIds.includes(m));
            this.props.selectMaterials(commonMaterials);

        }
        // Else if there are no materials on the changed simulation - reset to empty material selection
        else {
            this.props.selectMaterials([]);
        }
    }

    selectPriorityContourType = () => {
        var contourPriorityList = [
            "DOSAGE",
            "BLAST",
            "LCT50",
            "ICT50",
            "ECT50",
            "AEGLS",
            "DEPOSITION",
            "CONCENTRATION",
        ];

        const simDataTypes = this.props.resultsState.dataTypes[
            this.props.simulationState.selectedSimulation.id
        ];
        let foundMatch = false;
        if (simDataTypes) {
            for (const contour in contourPriorityList) {
                const matchedDataType = simDataTypes.find(
                    (dt) => dt.name === contourPriorityList[contour] // eslint-disable-line security/detect-object-injection
                );
                if (matchedDataType) {
                    this.props.selectDataTypes([matchedDataType.id]);
                    foundMatch = true;
                    break;
                }
            }

            // If the priority list did not contain any of the available contours
            // select the first contour available by default
            if (!foundMatch && simDataTypes.length > 0) {
                this.props.selectDataTypes([simDataTypes[0].id])
            }
        }

    };

    getContent = () => {
        var { t } = this.props;
        if (this.props.activeItem === this.props.name) {
            return (
                <PanelWrapper
                    key="p1"
                    title={t("simulationFilterTool.simulationFilterTool")}
                    cancel={this.props.cancel}
                >
                    <SimulationFilterMenu />
                </PanelWrapper>
            );
        }
        return null;
    };

    render() {
        if (this.props.simulationState.selectedSimulation === null || this.props.simulationState.selectedSimulation.id === null || this.props.resultsState === null || this.props.resultsState === undefined
            || this.props.resultsState.materials[this.props.simulationState.selectedSimulation.id] === undefined) {
            return null;
        }

        return (
            <>
                <Popup
                    className="popup"
                    mouseEnterDelay={300}
                    trigger={
                        <Menu.Item
                            className="icon-container"
                            name={this.props.name}
                            active={this.props.activeItem === this.props.name}
                            onClick={this.props.onClick}
                        >
                            <OutputIcon className="sidebar-icon" />
                        </Menu.Item>
                    }
                    position={"right center"}
                >
                    {this.props.title}
                </Popup>

                <div className="menu-option">{this.getContent()}</div>
            </>
        );
    }
}

/*
 * Maps state from the store to properties used by this class
 */
const mapStateToProps = (store, props) => {
    return {
        simulationState: store.simulationState,
        resultsState: store.resultsState,
        ...props,
    };
};

/*
 * Maps properties to dispatch methods to send actions to the store reducers
 */
const mapDispatchToProps = (dispatch) => {
    return {
        selectDataTypes: (ids) => {
            dispatch(ResultsActions.selectDataTypes(ids));
        },
        selectMaterials: (ids) => {
            dispatch(ResultsActions.selectMaterials(ids));
        },
    };
};

export default compose(
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps)
)(OutputMenu);
