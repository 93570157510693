
import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { Radio } from "semantic-ui-react";
import "whatwg-fetch";
import { Actions } from "../../../services/met/actions.jsx";
import MetSourceTypeEnum from "../../../enums/metSourceTypeEnum.jsx";

const OFFLINE_MAPS = window.env.REACT_APP_OFFLINE_MAPS === "true";

class MetTypeChoice extends React.Component {

    updateMetSourceType = newMetSourceType => {
        this.props.setMetConfig({
            ...this.props.metConfig,
            metSourceType: newMetSourceType
        }, this.props.scenarioId)
    }

    render() {
        const { t } = this.props;
        return <div>
            <Radio
                style={{display: "block"}}
                label={t("met.useMetPresets")}
                value={MetSourceTypeEnum.SCENARIO_PRESETS}
                checked={this.props.metConfig.metSourceType === MetSourceTypeEnum.SCENARIO_PRESETS}
                onChange={(e, { value }) => this.updateMetSourceType(value)}
            />
            <Radio
                style={{display: OFFLINE_MAPS ? "none" : "block"}}
                label={t("met.useMetService")}
                value={MetSourceTypeEnum.MET_SERVICE}
                checked={this.props.metConfig.metSourceType === MetSourceTypeEnum.MET_SERVICE}
                onChange={(e, { value }) => this.updateMetSourceType(value)}
            />
            <Radio
                style={{display: "block"}}
                label={t("met.useManualMet")}
                value={MetSourceTypeEnum.SIMULATION}
                checked={this.props.metConfig.metSourceType === MetSourceTypeEnum.SIMULATION}
                onChange={(e, { value }) => this.updateMetSourceType(value)}
            />
        </div>
    }
}

/*
 * Maps properties to dispatch methods to send actions to the store reducers
 */
const mapDispatchToProps = dispatch => {
    return {
        setMetConfig: (metConfig, scenarioId) => {
            dispatch(Actions.setMetConfig(metConfig, scenarioId));
        }
    }
};

export default compose(
    withTranslation(),
    connect(null, mapDispatchToProps)
)(MetTypeChoice);
