import React from 'react';
import { LayerGroup, LayersControl, WMSTileLayer } from 'react-leaflet';
import VectorGridC from '../VectorGridC';

const { Overlay } = LayersControl

export class OSLayers {

    static getMapLayers(t, orgLayers) {
        const configuredMapLayers = orgLayers;

        let mapLayers = [];
        if (configuredMapLayers != null && configuredMapLayers.includes("os_data_overlay")) {
            mapLayers.push(
                <Overlay name={t("map.os_highways")}>
                    <LayerGroup>
                        <VectorGridC
                            key="os_highways"
                            tileLayerName="os_highways"
                            layerStyle="os_highways"
                            url={"https://api.os.uk/maps/vector/v1/vts/highways/tile/{z}/{y}/{x}.pbf?srs=3857&key=c5YaR9tL4R5NFAyyewWcKvDL0AfpAg5P"}
                            minZoom={15}
                        />
                    </LayerGroup>
                </Overlay>
            )

            mapLayers.push(
                <Overlay name={t("map.os_greenspace")}>
                    <LayerGroup>
                        <VectorGridC
                            key="os_greenspace"
                            tileLayerName="os_greenspace"
                            layerStyle="os_greenspace"
                            url={"https://api.os.uk/maps/vector/v1/vts/greenspace/tile/{z}/{y}/{x}.pbf?srs=3857&key=c5YaR9tL4R5NFAyyewWcKvDL0AfpAg5P"}
                            minZoom={15}
                        />
                    </LayerGroup>
                </Overlay>
            )

            mapLayers.push(
                <Overlay name={t("map.os_boundaries")}>
                    <LayerGroup>
                        <VectorGridC
                            key="os_boundaries"
                            tileLayerName="os_boundaries"
                            layerStyle="os_boundaries"
                            url={"https://api.os.uk/maps/vector/v1/vts/boundaries/tile/{z}/{y}/{x}.pbf?srs=3857&key=c5YaR9tL4R5NFAyyewWcKvDL0AfpAg5P"}
                            minZoom={15}
                        />
                    </LayerGroup>
                </Overlay>
            )

            mapLayers.push(
                <Overlay name={t("map.os_sites")}>
                    <LayerGroup>
                        <VectorGridC
                            key="os_sites"
                            tileLayerName="os_sites"
                            layerStyle="os_sites"
                            url={"https://api.os.uk/maps/vector/v1/vts/sites/tile/{z}/{y}/{x}.pbf?srs=3857&key=c5YaR9tL4R5NFAyyewWcKvDL0AfpAg5P"}
                            minZoom={15}
                        />
                    </LayerGroup>
                </Overlay>
            )

            mapLayers.push(
                <Overlay name={t("map.os_paths")}>
                    <LayerGroup>
                        <VectorGridC
                            key="os_paths"
                            tileLayerName="os_paths"
                            layerStyle="os_paths"
                            url={"https://api.os.uk/maps/vector/v1/vts/paths/tile/{z}/{y}/{x}.pbf?srs=3857&key=c5YaR9tL4R5NFAyyewWcKvDL0AfpAg5P"}
                            minZoom={15}
                        />
                    </LayerGroup>
                </Overlay>
            )

            mapLayers.push(
                <Overlay name={t("map.os_water")}>
                    <LayerGroup>
                        <VectorGridC
                            key="os_water"
                            tileLayerName="os_water"
                            layerStyle="os_water"
                            url={"https://api.os.uk/maps/vector/v1/vts/water/tile/{z}/{y}/{x}.pbf?srs=3857&key=c5YaR9tL4R5NFAyyewWcKvDL0AfpAg5P"}
                            minZoom={15}
                        />
                    </LayerGroup>
                </Overlay>
            )

        }

        return mapLayers;
    }

}