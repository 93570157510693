import {
    SHOW_OUTPUT_PARAMS_POPUP,
    SHOW_SENSORS_POPUP,
    SHOW_KEY_BUILD_POPUP,
    HIDE_POPUP
} from './actions.jsx';


export class PopupReducer {

    static popupInfo(state, action) {

        const initialState = {
            sensorData: null,
            outputParams: null,
            keyBuildId: null
        }

        switch (action.type) {
            case SHOW_SENSORS_POPUP:
                return Object.assign({}, state, {
                    sensorData: action.payload.sensorData,
                    outputParams: null,
                    keyBuildId: null
                })
            case SHOW_OUTPUT_PARAMS_POPUP:
                return Object.assign({}, state, {
                    outputParams: action.payload.outputParams,
                    sensorData: null,
                    keyBuildId: null
                })
            case SHOW_KEY_BUILD_POPUP:
                return Object.assign({}, state, {
                    keyBuildId: action.payload.id,
                    sensorData: null,
                    outputParams: null
                })
            case HIDE_POPUP:
                return initialState;
            default:
                return state || initialState;
        }
    }


}