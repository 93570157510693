import Requests from '../requests';
import MetStabilityEnum from "../../enums/metStabilityEnum.jsx";

export const PRESET_MET_PROFILE_TYPE = "ScenarioMetProfileObservation";

/*
* action types
*/
export const CREATE_MET_PRESET = 'CREATE_MET_PRESET';
export const EDIT_MET_PRESET = 'EDIT_MET_PRESET';
export const CANCEL_EDIT_MET_PRESET = 'CANCEL_EDIT_MET_PRESET';
export const UPDATE_MET_PRESET = 'UPDATE_MET_PRESET';
export const REMOVE_MET_PRESET = 'REMOVE_MET_PRESET';
export const CLEAR_MET_PRESETS = 'CLEAR_MET_PRESETS';

/**
 * Creates actions for met 
 */
export class Actions {

	/*
	* action creator to make a new empty met preset (+ button)
	*/
	static createCreateMetPresetAction = scenarioId => {
		const metPreset = {
			scenarioId: scenarioId,
			time: new Date(),
			geoJson: null,
			geometry: null,
			windSpeed: 4,
			windDirection: 0,
			referenceHeight: 10,
			temperature: 293.15,
			stability: MetStabilityEnum.D
		}
		return {
			type: CREATE_MET_PRESET,
			payload: metPreset
		}
	}

	/*
	* action creator to edit a met preset
	*/
	static createEditMetPresetAction = metPresetId => ({
		type: EDIT_MET_PRESET,
		payload: metPresetId
	})

	/*
	* action creator for [X] button
	*/
	static createCancelEditAction = () => ({
		type: CANCEL_EDIT_MET_PRESET
	})

	/*
	* action creator to update a met preset
	*/
	static createUpdateMetPresetAction = metPreset => {
		metPreset.lastUpdated = new Date().getTime();
		return {
			type: UPDATE_MET_PRESET,
			payload: metPreset
		}
	}

	/*
	* action creator to remove a met preset
	*/
	static createRemoveMetPresetAction = metPresetId => {
		return {
			type: REMOVE_MET_PRESET,
			payload: metPresetId
		}
	}
	
	/**
	 * action creator to clear all met presets
	 */
	static createClearMetPresetsAction = () => ({
		type: CLEAR_MET_PRESETS
	})

	/**
	 * @param {Object} metPreset the met preset to save
	 * @returns a function that takes dispatch and updates the backend with the saved met preset,
	 * dispatching redux actions as appropriate during the process
	 */
	static saveMetPresetFunctionCreator = metPreset => {
		return metPreset.id ? this.updateMetPresetFunctionCreator(metPreset) : this.createMetPresetFunctionCreator(metPreset);
	}

	/**
	 * @param {Object} metPreset the new met preset to create
	 * @returns a function that takes dispatch and posts a new met preset to the backend,
	 * dispatching redux actions as appropriate during the process
	 */
	static createMetPresetFunctionCreator = metPreset => {
		metPreset.metProfileType = PRESET_MET_PROFILE_TYPE;
		return dispatch => {
			return Requests.post(`/met/met-profile/scenario/${metPreset.scenarioId}`, metPreset)
				.then(json => {
					json.metType = "profile"; // Needed for display of correct location pin
					json.geometry = metPreset.geometry;
					json.geoJson = metPreset.geoJson;
					json.time = new Date(json.time)
					dispatch(this.createUpdateMetPresetAction(json));
					return json;
				})
				.catch(error => {
					console.error(error);
				});
		}
	}

	/**
	 * @param {Object} metPreset the met preset to update the existing one with
	 * @returns a function that takes dispatch and puts an update met preset to the backend,
	 * dispatching redux actions as appropriate during the process
	 */
	static updateMetPresetFunctionCreator = metPreset => {
		metPreset.metProfileType = PRESET_MET_PROFILE_TYPE;
		return dispatch => {
			return Requests.put(`/met/met-profile/scenario/${metPreset.scenarioId}`, metPreset)
				.then(() => {
					dispatch(this.createUpdateMetPresetAction(metPreset));
				})
				.catch(error => {
					console.error(error);
				});
		}
	}

	/**
	 * @param {Object} metPreset the met preset to delete
	 * @returns a function that takes dispatch and deletes a met preset on the backend,
	 * dispatching redux actions as appropriate during the process
	 */
	static deleteMetPresetFunctionCreator = metPreset => {
		return dispatch => {
			return Requests.deleteRequest(`/met/met-profile/${metPreset.id}/scenario/${metPreset.scenarioId}`)
				.then(() => {
					dispatch(this.createRemoveMetPresetAction(metPreset.id));
				})
				.catch(error => {
					console.error(error);
				});
		};
	}

}