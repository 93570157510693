import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { Button, Input } from 'semantic-ui-react';
import StatusMessage from "../../../common/StatusMessage";
import "./styles.scss";
import { CoordinateUtils } from "../../../utils/CoordinateUtils";
import { CoordinateUnits } from "../../../utils/Units";

class SearchPanel extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      coordinateUnit: props.userState.user.preferences.coordinateUnit,
      lat: "",
      lon: "",
      mgrs: "",
      errorMessage: null
    }
  }

  /**
   * Creates the lat lon object to pass to map.flyTo using inputs, returning an object containing the object
   * if successful and information on errors if the inputs were invalid
   * @returns object { Optional[String] errorMessage, Optional[Object] validatedLatLonObject }
   */
  processInput() {
    if (this.state.coordinateUnit === CoordinateUnits.MGRS) {
      return this.validateMgrs();
    } else {
      return this.validateLatLon();
    }
  }

  validateLatLon() {
    // Verify that both values are numbers
    if (isNaN(this.state.lat) || isNaN(this.state.lon)) {
      return {
        errorMessage: this.props.t("search.errorMessageInvalid")
      }
    }

    // Verify that both numbers are within range
    if (this.state.lat < -90 || this.state.lat > 90 || this.state.lon < -180 || this.state.lon > 180) {
      return {
        errorMessage: this.props.t("search.errorMessageOutOfRange")
      }
    }

    return {
      validatedLatLonObject: { lat: this.state.lat, lon: this.state.lon }
    }
  }

  validateMgrs() {
    let lonLatCoordinates;
    try {
      lonLatCoordinates = CoordinateUtils.convertMgrsToLonLat(this.state.mgrs);
    } catch(exception) {
      return { errorMessage: this.props.t("source.messages.invalidCoordinates") };
    }

    return {
      validatedLatLonObject: { lat: lonLatCoordinates[1], lon: lonLatCoordinates[0] }
    }
  }

  handleChangeLat = (event,data) => {
    this.setState({ lat: data.value, errorMessage: null });
  }

  handleChangeLon = (event,data) => {
    this.setState({ lon: data.value, errorMessage: null });
  }

  handleChangeMgrs = (event,data) => {
    this.setState({ mgrs: data.value, errorMessage: null });
  }

  handleClick = ()  => {
    const { errorMessage, validatedLatLonObject } = this.processInput();
    if (!!errorMessage) {
      this.setState({ errorMessage: errorMessage })
    } else {
      this.props.mapState.map.flyTo(validatedLatLonObject)
    }
  }

  shouldDisableSearch = () => {
    if (!!this.state.errorMessage) {
      // Error messages are removed once the input is edited
      return true;
    }
    if (this.state.coordinateUnit === CoordinateUnits.MGRS) {
      return this.state.mgrs === "";
    } else {
      return this.state.lat === "" || this.state.lon === "";
    }
  }

  createSearchInputField = () => {
    if (this.state.coordinateUnit === CoordinateUnits.MGRS) {
      return <div className="search-panel input-boxes">
        <div className="coordinate-input">
          <Input
            placeholder={this.props.t("search.mgrs")}
            onChange={this.handleChangeMgrs}
          />
        </div>
      </div>
    } else {
      return <div className="search-panel input-boxes">
        <div className="coordinate-input">
          <Input
          placeholder={this.props.t("search.lat")}
          onChange={this.handleChangeLat}
          />
        </div>
        <div style={{flex:1,textAlign:"center",alignSelf:"center"}}>
          <b style={{color:'black'}}>/</b>
        </div>
        <div className="coordinate-input">
          <Input
          placeholder={this.props.t("search.lon")}
          onChange={this.handleChangeLon}
          />
        </div>
      </div>
    }
  }

  render() {
    const { t } = this.props;
    
    return (
      <div className="search-panel">
        {this.createSearchInputField()}
        <div className="bottom-bar finished"
        style={{gridRow:"3 / span 1"}}>
          <Button
            disabled={this.shouldDisableSearch()}
            type="button"
            loading={null}
            style={{margin:"0px"}}
            onClick={this.handleClick}
          >
            {t("search.search")}
          </Button>
        </div>
        <div>
        {!!this.state.errorMessage &&
          <StatusMessage
            isError={true}
            messageHeader={t('app.error')}
            messageContent={this.state.errorMessage}
          />
        }
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store,props) => {
  return {
    mapState: store.mapState,
    userState: store.userState,
    ...props,
  };
};

export default compose(
  withTranslation(),
  connect(mapStateToProps)
)(SearchPanel);
