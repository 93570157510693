import React from "react";
import ReactDOM from 'react-dom';
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { Button, Form, Loader, Message, Modal } from 'semantic-ui-react';
import {
    Actions,
    UPDATE_SCENARIO_FAILURE
} from "../../../services/scenario/actions.jsx";

class ScenarioEditPopup extends React.Component {

    state = {
        open: false,
        name: null,
        notes: null,
        loading: false,
        error: null
    }

    componentDidUpdate = (prevProps, prevState) => {
        var { t } = this.props;

        // Only perform changes if this component has been in loading state - otherwise store chagnes may have
        // not been initialised by this component
        if (this.state.loading) {
            if (prevProps.scenarioState.updatingScenario && !this.props.scenarioState.updatingScenario) {
                // If an error occured - keep modal open and display error message
                if (this.props.scenarioState.actionType === UPDATE_SCENARIO_FAILURE) {
                    this.setState({ error: t("scenario.messages.failedToUpdateScenario"), loading: false });
                } else {
                    this.setState({ loading: false, open: false });
                }
            }
        }

        // If the popup opened - focus on the title field
        if (this.state.open && !prevState.open) {
            if (this.modal) {
                // this.modal.focus();
                var input = ReactDOM.findDOMNode(this.modal).querySelector(".title-input input");
                if (input) {
                    input.focus();
                    input.select();
                }
            }
        }

        if (prevProps.open !== this.props.open && this.props.open) {
            this.setOpen(true);
        }
    };
    updateScenario = () => {
        this.setState({ error: null, loading: true });

        var scenario = {
            id: this.props.scenarioState.scenario.id,
            name: this.state.name,
            notes: this.state.notes,
        };
        this.props.updateScenario(scenario);
    }

    handleKeypress = e => {
        //it triggers by pressing the enter key
        if (e.key === 'Enter') {
            this.updateScenario();
        }
    };

    getScenarioForm = () => {
        const { t } = this.props;
        if (this.state.loading) {
            return (
                <Loader active inline='centered' size='massive'>{t("scenario.loading")}</Loader>
            );
        }
        return (
            <Form className="ua-form">
                <Form.Group>
                    <Form.Field>
                        <Form.Input
                            inline
                            className="title-input"
                            label={t("scenario.scenarioName")}
                            placeholder={t("scenario.scenarioName")}
                            value={this.state.name}
                            onChange={(e) => this.setState({ name: e.target.value })}
                            onKeyPress={this.handleKeypress}
                        />
                    </Form.Field>
                </Form.Group>
                <Form.Field>
                    <Form.TextArea
                        inline
                        label={t("sidebar.notes")}
                        placeholder={t("sidebar.enterNotes")}
                        value={this.state.notes}
                        rows={3}
                        onChange={(e) => this.setState({ notes: e.target.value })}
                        onKeyPress={this.handleKeypress}
                    />
                </Form.Field>
                {this.state.error ? (
                    <Message negative className="ua-error">
                        <Message.Header>{t("app.error")}</Message.Header>
                        <p>{this.state.error}</p>
                    </Message>
                ) : null}
            </Form>
        )
    }

    setOpen = (open) => {
        if (open) {
            // Open popup to edit scenario name and notes
            this.setState({
                open: open,
                name: this.props.scenarioState.scenario.name,
                notes: this.props.scenarioState.scenario.notes
            })
        } else {
            if (this.props.close) {
                // If modal is driven via outside logic
                // rather than the trigger component
                // Notify the parent that modal is closed
                this.props.close();
            }
            //Close the popup
            this.setState({
                open: open,
                name: null,
                notes: null
            })
        }
    }

    render() {
        const { t } = this.props;
        if (this.props.scenarioState.scenario && this.props.scenarioState.scenario.id) {
            return (
                <div className="scenario-title">
                    <Modal
                        className="ua-modal title-modal"
                        ref={(modal) => { this.modal = modal; }}
                        onClose={() => this.setOpen(false)}
                        onOpen={() => this.setOpen(true)}
                        open={this.state.open}
                        trigger={this.props.trigger}
                    >
                        <Modal.Header>{t("scenario.labels.updateScenario")}</Modal.Header>
                        <Modal.Content>
                            {this.getScenarioForm()}
                        </Modal.Content>
                        <Modal.Actions>
                            <Button color='black' onClick={() => this.setOpen(false)}>
                                {t("app.cancel")}
                            </Button>
                            <Button
                                content={t("app.update")}
                                disabled={this.state.loading}
                                onClick={() => this.updateScenario()}
                                positive
                            />
                        </Modal.Actions>
                    </Modal>
                </div>
            );
        }
        return null;
    }
}

const mapStateToProps = (store, props) => {
    return {
        scenarioState: store.scenarioState,
        ...props,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateScenario: (scenario) => {
            dispatch(Actions.updateScenario(scenario));
        },
    };
};

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(ScenarioEditPopup);
