/**
 * Enum for meteorology stability values used in met profiles and parsable by the backend
 */
const MetStabilityEnum = {
    A: 'A',
    B: 'B',
    C: 'C',
    D: 'D',
    E: 'E',
    F: 'F',
    G: 'G'
};

export default MetStabilityEnum;