import L from 'leaflet';
import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { Table } from "semantic-ui-react";
import { Actions } from "../../../services/metPresets/actions.jsx";
import CommonButtonsBar from "../../common/CentreEditAddRemoveButtonBar.jsx";
import { AngleUtils } from '../../utils/AngleUtils.jsx';

/**
 * Panel on which the user may view/delete current met observation presets, and from which the user navigates to create/edit them
 */
class MetPresetsDisplay extends React.Component {
  
  state = {
    selectedMetPresetId: null,
  };

  createTable = t => <Table celled structured>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>
          {t("met.time")}
        </Table.HeaderCell>
        <Table.HeaderCell>
          {t("met.speed")}
        </Table.HeaderCell>
        <Table.HeaderCell>
          {t("met.direction")}
        </Table.HeaderCell>
      </Table.Row>
    </Table.Header>
    <Table.Body>{this.createRows(t)}</Table.Body>
  </Table>

  handleClickRow = metPresetId => {
    // Clicking selects the row if not already selected, otherwise deselects it
    const newSelectedMetPresetId = (this.state.selectedMetPresetId === metPresetId) ? null : metPresetId;
    this.setState({
      selectedMetPresetId: newSelectedMetPresetId,
    });
  };

  createRows = (t) => {
    const metPresetRows = [];
    const currentScenarioId = this.props.scenarioState.scenario.id;
    const metPresets = Object.values(this.props.metPresetsState.metProfiles).filter(met => met.scenarioId === currentScenarioId);

    for (const metPreset of metPresets) {
      metPresetRows.push(
        <Table.Row
          key={metPreset.id}
          onClick={(e) => this.handleClickRow(metPreset.id)}
          className={this.state.selectedMetPresetId === metPreset.id ? "active" : "met-row"}
        >
          <Table.Cell key={metPreset.id + "_name"} className={"met-cell"}>{metPreset.time.toLocaleString('en-GB')}</Table.Cell>
          <Table.Cell key={metPreset.id + "_windspeed"} className={"met-cell"}>{metPreset.windSpeed}</Table.Cell>
          <Table.Cell key={metPreset.id + "_winddirection"} className={"met-cell"}>
            {AngleUtils.convertDegreesToDisplayString(metPreset.windDirection, this.props.userState.user.preferences.windDirectionUnit)}
          </Table.Cell>
        </Table.Row>
      );
    }

    if (metPresetRows.length === 0) {
      metPresetRows.push(
        <Table.Row key="default">
          <Table.Cell>{t("met.clickPlus")}</Table.Cell>
        </Table.Row>
      );
    }
    return metPresetRows;
  };

  flyToSelectedMet = () => {
    if (this.state.selectedMetPresetId != null) {
      const met = this.props.metPresetsState.metProfiles[this.state.selectedMetPresetId];
      const coord = met.geometry.coordinates;
      // Met geometry is a point - so we can safely fly to the first coordinate
      this.props.mapState.map.flyTo(new L.latLng(coord[1], coord[0]), 15);
    }
  };

  editSelectedMet = () => {
    if (this.state.selectedMetPresetId != null) {
      this.props.editMetPreset(this.state.selectedMetPresetId);
    }
  };

  removeSelectedMet = () => {
    if (this.state.selectedMetPresetId != null) {
      const met = this.props.metPresetsState.metProfiles[this.state.selectedMetPresetId];
      this.props.removeMetPreset(met);
      this.setState({ selectedMetPresetId: null });
    }
  };

  createMet = () => {
    this.props.createMetPreset();
  };

  render() {
    var { t } = this.props;
    return <div className="met-display">
      <div className="sources-table">{this.createTable(t)}</div>
      <div className="table-buttons">
      </div>
      <div className="bottom-bar">
        <CommonButtonsBar
          flyTo={this.flyToSelectedMet}
          flyToItemDescription={t("met.flyToMet")}
          editItem={this.editSelectedMet}
          editItemDescription={t("met.editMet")}
          removeItem={this.removeSelectedMet}
          removeItemDescription={t("met.removeMet")}
          createItem={this.createMet}
          createItemDescription={t("met.addMet")}
          selectedIndex={this.state.selectedMetPresetId}
        />
      </div>
    </div>
  }

}

/*
 * Maps state from the store to properties used by this class
 */
const mapStateToProps = (store, props) => {
  return {
    mapState: store.mapState,
    scenarioState: store.scenarioState,
    metPresetsState: store.metPresetsState,
    userState: store.userState,
    ...props,
  };
};

/*
 * Maps properties to dispatch methods to send actions to the store reducers
 */
const mapDispatchToProps = (dispatch) => {
  return {
    createMetPreset: () => {
      dispatch(Actions.createCreateMetPresetAction());
    },
    removeMetPreset: metPreset => {
      dispatch(Actions.deleteMetPresetFunctionCreator(metPreset));
    },
    editMetPreset: metPresetId => {
      dispatch(Actions.createEditMetPresetAction(metPresetId));
    },
  };
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(MetPresetsDisplay);
