
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Actions as PopupActions } from "../../../services/popups/actions.jsx";
import DetectorPopup from '../detectors/DetectorPopup.jsx';
import KeyBuildingPopup from './KeyBuildingPopup.jsx';
import OutputPopup from './OutputPopup.jsx';

class PopupDisplay extends Component {

    keybuildPopup = () => {
        return (
            <KeyBuildingPopup
                id={this.props.popupState.keyBuildId}
                key={this.props.popupState.keyBuildId}
                hidePopup={() => this.props.hidePopup()}
            />
        );
    }

    outputPopup = () => {
        return (
            <OutputPopup
                key={"output-popup"}
                queryParams={this.props.popupState.outputParams}
                hidePopup={() => this.props.hidePopup()}
            />
        );
    }

    detectorPopup = () => {
        return (
            <DetectorPopup
                key={this.props.popupState.sensorData.sensor.id}
                position={this.props.popupState.sensorData.location}
                hidePopup={() => this.props.hidePopup()}
                sensor={this.props.popupState.sensorData.sensor}
                concentration={this.props.popupState.sensorData.concentration}
                status={this.props.popupState.sensorData.status}
                sensorErrors={this.props.popupState.sensorData.sensorErrors}
            />
        );
    }

    getPopup = () => {
        if (this.props.popupState.keyBuildId !== null) {
            return this.keybuildPopup();
        }
        if (this.props.popupState.outputParams != null) {
            return this.outputPopup();
        }
        if (this.props.popupState.sensorData != null) {
            return this.detectorPopup();
        }

    }

    render() {
        return (
            <div>
                {this.getPopup()}
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        hidePopup: () => {
            dispatch(PopupActions.hidePopup());
        }
    }
}

const mapStateToProps = (store) => {
    return {
        popupState: store.popupState,
        keyBuildState: store.keyBuildState,
    }
}

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(PopupDisplay);