import Requests from '../requests';

export const COMPLETE_CONTOURS_SUCCESS = "COMPLETE_CONTOURS_SUCCESS";
export const COMPLETE_CONTOURS_FAILURE = "COMPLETE_CONTOURS_FAILURE";
export const COMPLETE_CONTOURS_BEGIN = "COMPLETE_CONTOURS_BEGIN";

export const INSERT_CONTOUR_SUCCESS = "INSERT_CONTOUR_SUCCESS";
export const EDIT_CONTOUR_CONFIG = "EDIT_CONTOUR_CONFIG";
export const CANCEL_CONTOUR_EDIT = "CANCEL_CONTOUR_EDIT";
export const REMOVE_CONTOUR_CONFIG = "REMOVE_CONTOUR_CONFIG";
export const UPDATE_CONTOUR_CONFIG = "UPDATE_CONTOUR_CONFIG";

export const FETCH_BEGIN = 'FETCH_CONTOUR_BEGIN';
export const FETCH_CONTOUR_CONFIGS_SUCCESS = "FETCH_CONTOUR_CONFIGS_SUCCESS";
export const FETCH_FAILURE = 'FETCH_CONTOUR_FAILURE';

export const CLEAR = 'CLEAR_CONTOUR';

export class ContourActions {

  static clear() {
    return {
      type: CLEAR
    }
  };

  /*
  * action creator for populating table
  */
  static fetchContourConfigs(scenarioId) {

    return dispatch => {
      dispatch(this.fetchBegin());
      return Requests.get("/contours/scenario/" + scenarioId)
        .then(json => {
          dispatch(this.fetchContourConfigsSuccess(json));
          return json;
        })
        .catch(error => {
          dispatch(this.fetchFailure(error))
        });
    };
  };

  /*
  * action creator to indicate contour configs retrieved
  */
  static fetchContourConfigsSuccess = contourConfigs => ({
    type: FETCH_CONTOUR_CONFIGS_SUCCESS,
    payload: { contourConfigs }
  });

  /*
  * action creator to start getting contour configs
  */
  static fetchBegin = () => ({
    type: FETCH_BEGIN
  });

  /*
  * action creator to indicate contour configs not retrieved
  */
  static fetchFailure = error => ({
    type: FETCH_FAILURE,
    payload: { error }
  });

  static insertContourConfig = (contourConfig, scenarioId, simulationId) => {

    return (dispatch) => {
      dispatch(this.onCompleteContourConfigBegin());
      return Requests.post(`/contours/scenario/${scenarioId}/simulation/${simulationId}`, [contourConfig])
        .then(json => {
          dispatch(this.onInsertContourConfigSuccess(json));
          return json;
        })
        .catch((err) => {
          console.error(err);
          dispatch(this.onCompleteContourConfigFailure(err));
        });
    };
  };

  static updateContourConfig = (contourConfig, scenarioId, simulationId) => {

    return (dispatch) => {
      dispatch(this.onCompleteContourConfigBegin());
      return Requests.put(`/contours/scenario/${scenarioId}/simulation/${simulationId}`, contourConfig)
        .then(() => dispatch(this.onUpdateContourConfigSuccess(contourConfig)))
        .catch((err) => {
          console.error(err);
          dispatch(this.onCompleteContourConfigFailure(err));
        });
    };
  };

  static onCompleteContourConfigBegin = () => {
    return {
      type: COMPLETE_CONTOURS_BEGIN
    };
  };

  static onInsertContourConfigSuccess = (contourConfig) => {
    return {
      type: INSERT_CONTOUR_SUCCESS,
      payload: contourConfig
    };
  };

  static onCompleteContourConfigFailure = (error) => {
    return {
      type: COMPLETE_CONTOURS_FAILURE,
      payload: { error }
    };
  };

  static onUpdateContourConfigSuccess = (contourConfig) => {
    return {
      type: UPDATE_CONTOUR_CONFIG,
      payload: contourConfig
    };
  };

  static editContourConfig = (selectedContourConfig) => {
    return {
      type: EDIT_CONTOUR_CONFIG,
      payload: selectedContourConfig
    };
  };

  static cancelContourEdit = () => {
    return {
      type: CANCEL_CONTOUR_EDIT
    };
  };

  static removeContourConfig(contourConfigId, scenarioId, simulationId) {

    return (dispatch) => {
      return Requests.deleteRequest(`/contours/${contourConfigId}/scenario/${scenarioId}/simulation/${simulationId}`)
        .then(() => {
          dispatch(this.onDeleteSuccess(contourConfigId));
        })
        .catch((error) => {
          console.error(error);
        });
    };
  };

  static onDeleteSuccess = (id) => {
    return {
      type: REMOVE_CONTOUR_CONFIG,
      payload: id
    };
  };

}
