/*
    ./client/components/App.jsx
*/
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Loader } from "semantic-ui-react";
import { Actions } from "../../../services/metPresets/actions.jsx";
import PanelWrapper from '../../common/panelWrapper.jsx';
import MetPresetsDisplay from './metPresetsDisplay.jsx';
import MetDataEditor from '../met/metDataEditor.jsx';

/**
 * View to contain logic determining if user is viewing or editing a met observation preset, and display sub-panel accordingly 
 */
class MetPresetsPanel extends React.Component {

    render() {
        const { t } = this.props;

        if (this.props.metPresetsState.editing) {
            return <div className="met-panel">
                <PanelWrapper key={"met" + this.props.metPresetsState.editing.id} title={t("met.editTimeSeries")} cancel={this.props.cancelEdit} >
                    <MetDataEditor
                        userPreferences={this.props.userState.user.preferences}
                        specificMetState={this.props.metPresetsState}
                        onMetDataUpdate={metData => this.props.saveMetPreset({ ...metData, scenarioId: this.props.selectedScenarioId } )}
                    />
                </PanelWrapper>
            </div>;
        }

        return <div className="met-panel">
            <PanelWrapper key={"metPresets" + this.props.selectedScenarioId} title={t("sidebar.metPresets")} cancel={this.props.cancel}>
                {this.props.metPresetsState.loading ? <Loader active inline='centered' size='medium' /> : <MetPresetsDisplay/>}
            </PanelWrapper>
        </div>;
    }

}

/*
* Maps state from the store to properties used by this class
*/
const mapStateToProps = (store, props) => {
    return {
        selectedScenarioId: store.scenarioState.scenario.id,
        metPresetsState: store.metPresetsState,
        userState: store.userState,
        ...props
    }
}

/*
* Maps properties to dispatch methods to send actions to the store reducers
*/
const mapDispatchToProps = (dispatch) => {
    return {
        cancelEdit: () => {
            dispatch(Actions.createCancelEditAction());
        },
        saveMetPreset: metPreset => {
            dispatch(Actions.saveMetPresetFunctionCreator(metPreset));
        }
    }
}


export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(MetPresetsPanel);