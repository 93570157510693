import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import ScenarioEditPopup from "../../common/popups/scenarioEditPopup.jsx";

class ScenarioTitle extends React.Component {

    render() {
        const { t } = this.props;
        if (this.props.scenarioState.scenario && this.props.scenarioState.scenario.id) {
            return (
                <ScenarioEditPopup trigger={
                    <div className="title-div">
                        {this.props.scenarioState.scenario.name}
                    </div>}
                />
            );
        }
        return null;
    }
}

const mapStateToProps = (store, props) => {
    return {
        scenarioState: store.scenarioState,
        ...props,
    };
};

export default compose(withTranslation(), connect(mapStateToProps))(ScenarioTitle);
