import React from "react";
import Requests from '../../../../services/requests';
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { Menu, Popup } from "semantic-ui-react";
import { ReactComponent as AuditIcon } from "../../../../images/audit.svg";
import { Actions } from "../../../../services/audits/actions.jsx";
import PanelWrapper from "../../../common/panelWrapper.jsx";
import AuditPanel from "./auditPanel";
import "./styles.scss";

const FETCH_INTERVAL = 5000;

class AuditMenu extends React.Component {

  componentDidMount() {
    // Poll audits (delay initial fetch)
    setTimeout(this.fetchAudits, 3000);
    this.fetchTimer = setInterval(this.fetchAudits, FETCH_INTERVAL);
  }

  componentWillUnmount() {
    // Remove the fetch polling timer
    clearInterval(this.fetchTimer);
    this.fetchTimer = null;
  }

  fetchAudits = () => {
    // Only fetch the audits if the user is verified
    if (this.props.userState.verified) {
      this.props.fetchAudits(this.props.scenarioState.scenario.id, this.props.auditState.lastFetchTime);
    }
  }

  downloadReport = (scenarioId, simulationId) => {

    const queryParam = {};
    if (scenarioId) {
      queryParam.scenarioId = scenarioId;
    }
    else if (simulationId) {
      queryParam.simulationId = simulationId;
    }

    let url = '/audits/report?' + new URLSearchParams(queryParam)
    Requests.get(url, true)
      .then(
            blob => {
              let file = window.URL.createObjectURL(blob);
              var fileLink = document.createElement('a');
              fileLink.href = file;
              fileLink.download = "audit-report-" + new Date().toISOString() + ".docx";
              fileLink.click();
              fileLink.remove();
            }
          )
      .catch(err => {
        console.error("Error downloading report", err);
      })
  }

  getContent = () => {
    let { t } = this.props;
    if (this.props.activeItem === 'audits') {
      return (
        <PanelWrapper
          key="a1"
          title={t("auditTool.audits")}
          cancel={this.props.cancel}
        >
          <AuditPanel
            downloadReport={this.downloadReport}
          />
        </PanelWrapper>
      );
    }
    return null;
  }

  render() {
    var { t } = this.props;
    return (
      <>
        <Popup
          className="popup"
          mouseEnterDelay={300}
          trigger={
            <Menu.Item
              className="icon-container"
              name="audits"
              active={this.props.activeItem === "audits"}
              onClick={this.props.onClick}
            >
              <AuditIcon className="sidebar-icon" />
            </Menu.Item>
          }
          position={"right center"}
        >
          {t("auditTool.audits")}
        </Popup>

        <div className="menu-option">{this.getContent()}</div>
      </>
    )
  }
}

/*
 * Maps state from the store to properties used by this class
 */
const mapStateToProps = (store, props) => {
  return {
    scenarioState: store.scenarioState,
    auditState: store.auditState,
    userState: store.userState,
    ...props,
  };
};

/*
 * Maps properties to dispatch methods to send actions to the store reducers
 */
const mapDispatchToProps = (dispatch) => {
  return {
    fetchAudits: (scenarioId, forTime) => {
      dispatch(Actions.fetchAudits(scenarioId, forTime));
    },
  };
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(AuditMenu);
