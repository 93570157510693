import L from 'leaflet';

export default L.TileLayer.BetterWMS = L.TileLayer.WMS.extend({

    onAdd: function (map) {
        // Triggered when the layer is added to a map.
        //   Register a click listener, then do all the upstream WMS things
        L.TileLayer.WMS.prototype.onAdd.call(this, map);
    },

    onRemove: function (map) {
        // Triggered when the layer is removed from a map.
        //   Unregister a click listener, then do all the upstream WMS things
        L.TileLayer.WMS.prototype.onRemove.call(this, map);
        map.off('click', this.getFeatureInfo, this);
    },

    getFeatureInfo: function (evt) {
        // Make an AJAX request to the server and hope for the best
        var url = this.getFeatureInfoUrl(evt.latlng),
            showResults = L.Util.bind(this.showGetFeatureInfo, this);
        // This fetch goes off directly to call to geoserver, which we do not authenticate so does not need
        // to go via our generic fetching requests class
        fetch(url)
            .then(resp => {
                // Under ticket #1610 there was an issue parsing to JSON without luck to recreate the issue
                // So parse the body to text first, and then attempt to parse to JSON, in order to know what text was being converted
                resp.text()
                    .then(responseText => {
                        try {
                            const json = JSON.parse(responseText);
                            if (json.features && json.features.length > 0) {
                                showResults(null, evt.latlng, json);
                            }
                        } catch (err) {
                            throw new Error("Failed to parse GetFeatureInfo response into JSON: " + responseText)
                        }
                    })
            })
            .catch(err => {
                showResults(err);
            })
    },

    getFeatureInfoUrl: function (latlng) {
        // Construct a GetFeatureInfo request URL given a point
        var point = this._map.latLngToContainerPoint(latlng, this._map.getZoom()),
            size = this._map.getSize(),

            params = {
                request: 'GetFeatureInfo',
                service: 'WMS',
                srs: 'EPSG:4326',
                styles: this.wmsParams.styles,
                transparent: this.wmsParams.transparent,
                version: this.wmsParams.version,
                format: this.wmsParams.format,
                bbox: this._map.getBounds().toBBoxString(),
                height: size.y,
                width: size.x,
                cql_filter: this.wmsParams.cql_filter,
                layers: this.wmsParams.layers,
                query_layers: this.wmsParams.layers,
                time: this.wmsParams.time,
                feature_count: 10,
                info_format: 'application/json'
            };

        params[params.version === '1.3.0' ? 'i' : 'x'] = Math.round(point.x);
        params[params.version === '1.3.0' ? 'j' : 'y'] = Math.round(point.y);

        return this._url + L.Util.getParamString(params, this._url, true);
    },

    showGetFeatureInfo: function (err, latlng, content) {
        if (err) { console.log(err); return; } // do nothing if there's an error

        const firstFeature = content.features[0].properties;
        const queryParam = {};
        queryParam.simulationId = firstFeature['simulation_id'];
        queryParam.dataTypeId = firstFeature['data_type_id'];
        queryParam.materialId = firstFeature['material_id'];
        queryParam.time = firstFeature['simulation_time'];

        this.showOutputPopup(queryParam);

    },

    hide: function () {
        this._visible = false;
        if (this._container) {
            this._container.style.display = 'none';
        }
        // Remo the on click listener
        this.removeMapClickListener();
    },

    show: function () {
        this._visible = true;
        if (this._container) {
            this._container.style.display = 'block';
        }
        // Add on click listener
        this.addMapClickListener();

        if (this._map) {
            this._map.fire('layershow', this);
        }
    },

    showOutputPopup: function (data) {
        // This will be overridden by extension when creating the class locally
    },

    removeMapClickListener: function () {
        if (this._map) {
            this._map.off('click', this.getFeatureInfo, this);
        }
    },

    addMapClickListener: function () {
        if (this._map && this._visible) {
            this._map.on('click', this.getFeatureInfo, this);
        }
    },
});

L.tileLayer.betterWms = function (url, options) {
    return new L.TileLayer.BetterWMS(url, options);
};