import React, { Component } from 'react';
import Draggable from 'react-draggable';
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { Loader, Table } from 'semantic-ui-react';
import Requests from '../../../services/requests';
import '../map.scss';
import { Utils } from '../../../services/utils/utils';

// Map of Data Types and their units key for translation
const dataTypeUnits = {
    "CONCENTRATION": "kg_m3",
    "INDOOR_CONCENTRATION": "kg_m3",
    "INDOOR_DOSAGE": "kgs_m3",
    "DOSAGE": "kgs_m3",
    "LCT50": false,
    "ICT50": false,
    "ECT50": false,
    "AEGLS": false,
    "DEPOSITION": "kg_m2",
    "BLAST": "psi",
    "ATP-45": false
}

class OutputPopup extends Component {

    constructor(props) {
        super(props);

        this.state = {
            position: props.position,
            width: window.innerWidth,
            height: window.innerHeight,
            loading: true,
            error: false,
            queryParams: props.queryParams,
            outputData: []
        }

        this.wrapperRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
        window.addEventListener('resize', this.updateDimensions);

        const url = '/output/scenario/' + this.props.scenarioState.scenario.id + '?';
        const queryParams = this.state.queryParams;

        Requests.get(url + new URLSearchParams(queryParams))
            .then(contJson => {
                if (contJson) {
                    this.setState({ outputData: contJson, loading: false, error: false });
                } else {
                    console.error("failed to get contour data");
                    this.setState({ loading: false, error: true });
                }
            })
            .catch(err => {
                console.error("failed to get contour data", err);
                this.setState({ loading: false, error: true });
            })
    }


    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
        window.removeEventListener('resize', this.updateDimensions);
    }

    updateDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    };

    shouldComponentUpdate(prevProps, prevState) {
        return prevState.width !== this.state.width ||
            prevState.height !== this.state.height ||
            prevState.loading !== this.state.loading ||
            prevState.outputData.length !== this.state.outputData.length;
    }

    /**
     * Alert if clicked on outside of element
     */
    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
            this.props.hidePopup();
        }
    }


    getOutputView = () => {
        const { t } = this.props;

        if (this.state.loading) {
            return (
                <Loader active inline='centered'>{t("keyBuild.loadingData")}...</Loader>
            )
        }

        if (this.state.error) {
            return (
                t('messages.errorCheckLogs')
            )
        }

        if (this.state.outputData.length === 0) {
            return null;
        }

        const firstOutput = this.state.outputData[0];

        // All the output data will be for the same data type/material, get the first data type and material to use in title
        const dataTypeTitle = t('dataTypes.' + firstOutput.dataType);
        const materialTitle = firstOutput.material ? firstOutput.material : "";
        const dateTime = new Date(firstOutput.time).toLocaleString();
        let hasUnits = dataTypeUnits[firstOutput.dataType];
        let title = dataTypeTitle;
        if (hasUnits) {
            title += " (" + t('units.' + hasUnits) + ")";
        }

        return (
            <>
                <div style={{ "textAlign": "center" }}>
                    <div style={{ "fontWeight": "800", "fontSize": "16px" }}>{title}</div>
                    <div style={{ "fontWeight": "600", "fontSize": "14px" }}>{materialTitle}</div>
                    <div>{dateTime}</div>
                </div>
                <div style={{ "marginTop": "10px" }}>
                    <div className="chart-div">
                        {this.getTable()}
                    </div>
                </div>
                <div style={{ "textAlign": "center" }}>
                    {t("contourPopup.messages.appliesToSameMaterialsAndTypes")}
                </div>
            </>
        );
    }

    getTable = () => {
        const { t } = this.props;
        const tableBodyRows = [];

        let hasUnits = dataTypeUnits[this.state.outputData[0].dataType];

        for (let ind in this.state.outputData) {
            const output = this.state.outputData[ind];
            let population = t('contourPopup.messages.noData');
            if (output.population && output.population.population) {
                population = Math.floor(output.population.population);
            }
            tableBodyRows.push(<Table.Row key={"output-row-" + ind}>
                <Table.Cell><i style={{ background: output.colour, height: '18px', display: 'block' }} /></Table.Cell>
                <Table.Cell>{output.label}</Table.Cell>
                {!Utils.OFFLINE_MAPS ? <Table.Cell>{population}</Table.Cell> : null}
                <Table.Cell>{output.area}</Table.Cell>
            </Table.Row>)
        }

        return (
            <Table basic>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>{t('contourPopup.labels.key')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('contourPopup.labels.label')}</Table.HeaderCell>
                        {!Utils.OFFLINE_MAPS ? <Table.HeaderCell>{t('label.population')}</Table.HeaderCell> : null}
                        <Table.HeaderCell>{t('contourPopup.labels.area')}</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {tableBodyRows}
                </Table.Body>
            </Table>
        )
    }

    render() {
        const { t } = this.props;
        let currTime = this.props.currentTime;
        // Create a Draggable component bounds that will not allow to drag the popup
        // outside the visible window, with 200 pixels of the popup always visble
        return (
            <Draggable
                bounds={{
                    left: -this.state.position.x - 200,
                    top: -this.state.position.y - 200,
                    right: this.state.width - this.state.position.x - 200,
                    bottom: this.state.height - this.state.position.y - 200,
                }}>
                <div className="leaflet-popup-content-wrapper ua-popup output-popup" ref={this.wrapperRef}
                    style={{
                        left: this.state.position.x + 'px',
                        top: this.state.position.y + 'px'
                    }}>
                    {this.getOutputView()}
                </div>
            </Draggable>
        )
    }

}

const mapStateToProps = (store) => {
    return {
        simulationState: store.simulationState,
        scenarioState: store.scenarioState,
        currentTime: store.mapState.currentTime,
        position: store.mapState.position,
    }
}
export default compose(withTranslation(), connect(mapStateToProps))(OutputPopup);