import React from "react";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import PanelWrapper from "../common/panelWrapper.jsx";
import "./styles.scss";

/**
 * Modal that renders an iframe to display static information
 */
class StaticDataModal extends React.Component {

    render() {
        return (
            <div className="modal fullscreen">
                <PanelWrapper title={this.props.title} cancel={this.props.cancel}>
                    <iframe src={this.props.src} />
                </PanelWrapper>
            </div>
        );
    }
}

export default compose(withTranslation())(StaticDataModal);
