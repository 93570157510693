import React from "react";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import { Button, Form, Message } from "semantic-ui-react";
import FormFieldDate from "../../common/formFieldDate.jsx";
import InputFieldWithUnits from "../../common/inputFieldWithUnits.jsx";
import ShapeCreateEdit from "../sources/shapeCreateEditor.jsx";
import MetStabilityEnum from "../../../enums/metStabilityEnum.jsx";
import "../sources/sources.scss";

// Const used to define map marker style 
const MET_TYPE = "profile"    

/**
 * Definition of panel on which user creates/edits a met observation, inputting all values on this one view
 */
class MetDataEditor extends React.Component {
  

  /**
   * Constructor
   * @prop {Object} specificMetState the metState or metPresetsState from the redux store
   * @prop {Function} onMetDataUpdate function that takes the updated met data object once the user has finished editing it,
   *                  and converts to the appropriate met profile data type and updates the redux store
   */
  constructor(props) {
    super(props);

    const met = props.specificMetState.editing;
    // If this is a fetch that already has geometry then we now need a geojson to hold that geometry
    let geoJson = met.geoJson
    if (!met.geoJson && met.geometry) {
      geoJson = {
        type: "Feature",
        properties: {},
        geometry: met.geometry
      }
    }
    this.state = {
      loading: false,
      id: met.id,
      time: new Date(met.time),
      geoJson: geoJson,
      coordinateTextInvalid: false,
      geometry: met.geometry,
      windSpeed: met.windSpeed,
      windDirection: met.windDirection,
      referenceHeight: met.referenceHeight,
      temperature: met.temperature,
      stability: met.stability,
      selectingLocationFlag: false,
      fieldErrors: []
    }
  }

  componentDidUpdate(prevProps, prevState) {
    var { t } = this.props;
    this.validateFields(prevState);

    // If the component is in loading state - check the progress of the source update/insertion
    if (this.state.loading) {
      // if the incidentState has changed its loading property from true to false - check for errors
      // No need to check for success as on success the panel will change back to main panel
      if (prevProps.specificMetState.loading && !this.props.specificMetState.loading) {
        if (this.props.specificMetState.error) {
          this.setState({ loading: false, error: t("source.messages.failedToAddUpdate") })
        }
      }
    }
  }

  validateFields = (prevState) => {
    var { t } = this.props;

    var fieldErrors = {};
    if (prevState.windSpeed !== this.state.windSpeed) {
      fieldErrors['windSpeed'] = this.getValidMessage(this.state.windSpeed, this.validateWindSpeedSmall, t("met.messages.windSpeedSmallError"));
      if (!fieldErrors['windSpeed']) {
        fieldErrors['windSpeed'] = this.getValidMessage(this.state.windSpeed, this.validateWindSpeedLarge, t("met.messages.windSpeedLargeError"));
      }
    }
    if (prevState.windDirection !== this.state.windDirection) {
      fieldErrors['windDirection'] = this.getValidMessage(this.state.windDirection, this.validateWindDirection, t("met.messages.windDirectionError"));
    }
    if (prevState.referenceHeight !== this.state.referenceHeight) {
      fieldErrors['referenceHeight'] = this.getValidMessage(this.state.referenceHeight, this.isNumberGreaterThanZero, t("met.messages.referenceHeightError"));
    }
    if (prevState.temperature !== this.state.temperature) {
      fieldErrors['temperature'] = this.getValidMessage(this.state.temperature, this.validateTemperature, t("met.messages.temperatureError"), 'temperature');
    }
    if (Object.keys(fieldErrors).length) {
      fieldErrors = Object.assign({}, this.state.fieldErrors, fieldErrors);
      this.setState({ fieldErrors: fieldErrors });
    }
  }

  getValidMessage = (field, valid, message, inputType) => {
    let emptyMessage = this.getEmptyMessage(field, inputType)
    if (emptyMessage) {
      return emptyMessage;
    }
    if (!valid(field)) {
      return message
    } else {
      return false
    }
  }

  selectLoc = (e) => {
    this.setState({ selectingLocationFlag: true });
  };

  validate = () => {
    if (!this.state.time && this.state.time > 0) {
      return false;
    }
    if (!this.state.geoJson) {
      return false;
    }
    if (!this.state.time) {
      return false;
    }
    if (this.state.coordinateTextInvalid) {
      return false;
    }
    if (!Array.from(Object.values(this.state.fieldErrors)).every(v => v === false)) {
      return false;
    }
    return true;
  };

  getEmptyMessage = (field, inputType) => {
    var { t } = this.props;
    let nonwhitespace = /[^\s]/;
    let valid = (field != undefined && field.toString().match(nonwhitespace)) && !(inputType==='temperature' && field === '-');
    return !valid ? t("source.messages.emptyError") : "";
  }

  validateWindSpeedSmall = () => {
    return this.state.windSpeed > 0
  };

  validateWindSpeedLarge = () => {
    // Backend validates the max wind speed up to 50m/s for a more realistic supported wind speed.
    return this.state.windSpeed <= 50;
  };

  validateWindDirection = () => {
    return this.state.windDirection >= 0 && this.state.windDirection < 360 && this.state.windDirection !== "";
  };

  isNumberGreaterThanZero = (value) => {
    return value > 0 && !isNaN(value)
  };

  validateTemperature = () => {
    // -50 to +50 degC.
    return this.state.temperature >= 223.15 && this.state.temperature <= 323.15;
  };

  pushUpdatedMet = () => {
    const metData = {
      id: this.state.id,
      windSpeed: this.state.windSpeed,
      windDirection: parseFloat(this.state.windDirection),
      metType: MET_TYPE,
      referenceHeight: this.state.referenceHeight,
      temperature: this.state.temperature,
      time: this.state.time,
      geoJson: this.state.geoJson,
      geometry: this.state.geoJson.geometry,
      stability: this.state.stability
    };
    this.props.onMetDataUpdate(metData);
    this.setState({ loading: true });
  };

  getContent = () => {
    const { t } = this.props;
    return (
      <>
        <Form className="ua-form content-body met-editor">
          <Form.Group>
            <FormFieldDate
              dateFormat={this.props.userPreferences.dateFormat}
              t={t}
              label={t('incident.date')}
              required={true}
              initialValue={this.state.time}
              onChange={(value) => this.setState({ time: value })}
            />
          </Form.Group>

          <Form.Group>
            <InputFieldWithUnits
              inline required={true}
              label={t("met.windDirection")}
              unit={this.props.userPreferences.windDirectionUnit}
              value={this.state.windDirection}
              type="ANGLE"
              setValue={(v) => this.setState({ windDirection: v })}
              disabled={false}
              error={this.state.fieldErrors['windDirection']}
            />
          </Form.Group>

          <Form.Group>
            <InputFieldWithUnits
              inline required={true}
              label={t("met.windSpeed")}
              unit="m/s"
              value={this.state.windSpeed}
              type="SPEED"
              setValue={(v) => this.setState({ windSpeed: v })}
              disabled={false}
              error={this.state.fieldErrors['windSpeed']}
            />
          </Form.Group>

          <Form.Group>
            <InputFieldWithUnits
              inline required={true}
              label={t("met.referenceHeight")}
              unit="m"
              value={this.state.referenceHeight}
              type="SIZE"
              setValue={(v) => this.setState({ referenceHeight: v })}
              disabled={false}
              error={this.state.fieldErrors['referenceHeight']}
            />
          </Form.Group>

          <Form.Group>
            <InputFieldWithUnits
              inline required={true}
              label={t("met.temperature")}
              unit="degC"
              value={this.state.temperature}
              type="TEMPERATURE"
              setValue={(v) => this.setState({ temperature: v })}
              disabled={false}
              error={this.state.fieldErrors['temperature']}
            />
          </Form.Group>

          <Form.Group className="location-field">
            <Form.Field required={true}>
              <label>{t('incident.location')}</label>
              <ShapeCreateEdit
                className="ui input"
                shape="MARKER"
                setGeoJson={(geoJ) => this.setState({ geoJson: geoJ })}
                geoJson={this.state.geoJson}
                onCoordinatesInputErrorStateChange={(value) =>
                  this.setState({ coordinateTextInvalid: value })}
              />
            </Form.Field>
          </Form.Group>

          <Form.Group>
            <Form.Select
              inline
              required={true}
              label={t("met.stability")}
              options={Object.values(MetStabilityEnum).map(stability => ({key: stability, text: stability, value: stability}))}
              value={this.state.stability}
              onChange={(e, data) => this.setState({ stability: data.value })}
            />
          </Form.Group>

          {this.state.error ? (
            <Message negative className="ua-error">
              <Message.Header>{t("app.error")}</Message.Header>
              <p>{this.state.error}</p>
            </Message>
          ) : null}
        </Form>
        <div className="bottom-bar">
          <div className="finished">
            <Button
              type="button"
              onClick={this.pushUpdatedMet}
              disabled={!this.validate() || this.state.loading}
              loading={this.state.loading}
            >
              {t("sidebar.finished")}
            </Button>
          </div>
        </div>
      </>
    );
  };

  render() {
    return this.getContent();
  }
}

export default compose(
  withTranslation()
)(MetDataEditor);
