import 'proj4leaflet';
import 'leaflet.vectorgrid';
import * as WMS from "leaflet.wms";

export class KeyBuildUtils {

    static getBuildingFootprintLayers(selectOSBuilding) {
        const geoserverUrl = window.env.GEO_DATA_URL + '/geoserver/geo-data/wms';

        let layers = [];
        const OSBuildingSource = WMS.Source.extend({
            'showFeatureInfo': function (latlng, info) {
                selectOSBuilding(JSON.parse(info));
            }
        });
        const blayer = new OSBuildingSource(geoserverUrl,
            {
                "format": "image/png",
                "transparent": "true",
                "info_format": "application/json",
                "pane": "source-pane",
                "zIndex": 598
            });

        layers.push(blayer.getLayer("building_footprints"));

        return layers;

    }
}