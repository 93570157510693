import React from "react";
import ReactDOM from 'react-dom';
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { Button, Form, Loader, Message, Modal } from 'semantic-ui-react';
import {
    SimulationActions,
    UPDATE_SIMULATION_FAILURE
} from "../../../services/simulation/actions.jsx";

class SimulationEditPopup extends React.Component {

    state = {
        open: false,
        name: null,
        loading: false,
        error: null,
        fieldErrors: {},
    }

    componentDidUpdate = (prevProps, prevState) => {
        var { t } = this.props;

        // Only perform changes if this component has been in loading state - otherwise store changes may have
        // not been initialised by this component
        if (this.state.loading) {
            if (!this.props.simulationState.isUpdatingSimulation && prevProps.simulationState.isUpdatingSimulation) {
                // If an error occured - keep modal open and display error message
                if (this.props.simulationState.actionType === UPDATE_SIMULATION_FAILURE) {
                    this.setState({ error: t("run.messages.failedToUpdateSimulationName"), loading: false });
                } else {
                    // Simulation successfully updated - fire off parent callback if defined
                    if (this.props.onUpdateSuccess) {
                        this.props.onUpdateSuccess();
                    }
                    this.setState({ loading: false, open: false });
                }
            }
        }

        // If the popup opened - focus on the title field
        if (this.state.open && !prevState.open) {
            if (this.modal) {
                // this.modal.focus();
                var input = ReactDOM.findDOMNode(this.modal).querySelector(".title-input input");
                if (input) {
                    input.focus();
                    input.select();
                }
            }
        }

        if (prevProps.open !== this.props.open && this.props.open) {
            this.setOpen(true);
        }

        this.validateFields(prevState);
    };

    validateFields = (prevState) => {
        const { t } = this.props;
        // Copy existing field errors array
        var fieldErrors = Object.assign({}, this.state.fieldErrors);
        let validated = false;

        if (prevState.name !== this.state.name) {
            validated = true;
            fieldErrors['name'] = false;
            if (!this.state.name || this.state.name.trim() === '') {
                fieldErrors['name'] = t('simulation.messages.nameIsEmpty');
            }
        }

        if (validated) {
            this.setState({ fieldErrors: fieldErrors });
        }
    }

    updateSimulation = () => {

        const { t } = this.props;

        this.setState({ error: null, loading: true });

        const simulationDto = {
            type: 'SimulationName',
            id: this.props.simulationState.selectedSimulation.id,
            name: this.state.name
        };

        // Check model types need to be updated (when simulation is being run these are passed through props)
        // Then change the dto type
        if (this.props.updateModelTypes) {
            simulationDto.type = 'SimulationNameAndModelTypes';
            simulationDto.modelTypes = this.props.modelTypesToUpdate;
        }

        this.props.updateSimulation(simulationDto, this.props.scenarioState.scenario.id);
    }

    handleKeypress = e => {
        //it triggers by pressing the enter key
        if (e.key === 'Enter' && !this.isUpdateButtonDisabled()) {
            this.updateSimulation();
        }
    };

    getSimulationForm = () => {
        const { t } = this.props;
        if (this.state.loading) {
            return (
                <Loader active inline='centered' size='massive'></Loader>
            );
        }
        return (
            <Form className="ua-form">
                <Form.Group>
                    <Form.Field>
                        <Form.Input
                            inline
                            className="title-input"
                            label={t("simulation.simulationName")}
                            placeholder={t("simulation.simulationName")}
                            error={this.state.fieldErrors['name']}
                            value={this.state.name}
                            onChange={(e) => this.setState({ name: e.target.value })}
                            onKeyPress={this.handleKeypress}
                        />
                    </Form.Field>
                </Form.Group>
                {this.state.error ? (
                    <Message negative className="ua-error">
                        <Message.Header>{t("app.error")}</Message.Header>
                        <p>{this.state.error}</p>
                    </Message>
                ) : null}
            </Form>
        )
    }

    setOpen = (open) => {
        if (open) {
            // Open popup to edit simulation name and notes
            this.setState({
                open: open,
                name: this.props.simulationState.selectedSimulation.name,
            })
        } else {
            if (this.props.close) {
                // If modal is driven via outside logic
                // rather than the trigger component
                // Notify the parent that modal is closed
                this.props.close();
            }
            //Close the popup
            this.setState({
                open: open,
                name: null,
                notes: null
            })
        }
    }

    getUpdateButtonText = () => {
        const { t } = this.props;
        let text = t("app.update");
        if (this.props.updateButtonText) {
            text = this.props.updateButtonText;
        }
        return text;
    }

    getHeaderTitle = () => {
        const { t } = this.props;
        let text = t("simulation.labels.updateSimulation");
        if (this.props.headerTitle) {
            text = this.props.headerTitle;
        }
        return text;
    }

    isUpdateButtonDisabled = () => {
        return this.state.loading || this.state.fieldErrors['name'];
    }

    render() {
        const { t } = this.props;
        if (this.props.simulationState.selectedSimulation && this.props.simulationState.selectedSimulation.id) {
            return (
                <Modal
                    className="ua-modal title-modal"
                    ref={(modal) => { this.modal = modal; }}
                    onClose={() => this.setOpen(false)}
                    onOpen={() => this.setOpen(true)}
                    open={this.state.open}
                    trigger={this.props.trigger}
                >
                    <Modal.Header>{this.getHeaderTitle()}</Modal.Header>
                    <Modal.Content>
                        {this.getSimulationForm()}
                    </Modal.Content>
                    <Modal.Actions>
                        <Button color='black' onClick={() => this.setOpen(false)}>
                            {t("app.cancel")}
                        </Button>
                        <Button
                            content={this.getUpdateButtonText()}
                            disabled={this.isUpdateButtonDisabled()}
                            onClick={() => this.updateSimulation()}
                            positive
                        />
                    </Modal.Actions>
                </Modal>
            );
        }
        return null;
    }
}

const mapStateToProps = (store, props) => {
    return {
        simulationState: store.simulationState,
        scenarioState: store.scenarioState,
        ...props,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateSimulation: (simulation, scenarioId) => {
            dispatch(SimulationActions.updateSimulation(simulation, scenarioId));
        },
    };
};

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(SimulationEditPopup);
