import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { Button, Form, Message } from "semantic-ui-react";
import Requests from '../../../services/requests';
import "../appHeader.scss";

class PasswordChange extends React.Component {

    state = {
        oldPassword: "",
        newPassword: "",
        repeatNewPassword: "",
        fieldErrors: {},
        message: null,
        validate: false
    };

    componentDidUpdate(prevProps, prevState) {
        if (this.state.newPassword !== prevState.newPassword ||
            this.state.repeatNewPassword !== prevState.repeatNewPassword) {
            this.validateFields();
        }
    }

    validateFields = () => {
        const { t } = this.props;

        // If we should not validate - just set empty validation object
        if (!this.state.validate) {
            this.setState({ fieldErrors: {} });
            return;
        }

        // Copy existing field errors array
        var fieldErrors = Object.assign({}, this.state.fieldErrors);

        // Validate password
        fieldErrors['password'] = false;
        // Check the complexity of the password
        if (this.state.newPassword.length < 8) {
            fieldErrors['password'] = t('registration.messages.passwordMinLengthWarning');
        } else if ((!this.state.newPassword.match(/[a-z]/)) || (!this.state.newPassword.match(/[A-Z]/))) {
            fieldErrors['password'] = t('registration.messages.passwordLowerAndUpperCaseWarning');
        } else if (!this.state.newPassword.match(/\d+/)) {
            fieldErrors['password'] = t('registration.messages.passwordNumberWarning');
        } else if (!this.state.newPassword.match(/.[!,@,#,$,%,^,&,*,?,_,~,-,(,)]/)) {
            fieldErrors['password'] = t('registration.messages.passwordSpecialCharWarning');
        }

        fieldErrors['repeatNewPassword'] = false;
        if (this.state.repeatNewPassword !== this.state.newPassword) {
            fieldErrors['repeatNewPassword'] = t('registration.messages.passwordsDoNotMatch');
        }

        this.setState({ fieldErrors: fieldErrors });
    }

    validate = () => {
        return this.state.newPassword && !this.state.fieldErrors['password'] &&
            this.state.repeatNewPassword && !this.state.fieldErrors['repeatNewPassword'];
    }

    change = () => {
        const { t } = this.props;

        // Construct the object to hold the password change for API
        const passwordChange = {
            type: 'UserPasswordChange',
            id: this.props.userState.user.id,
            oldPassword: this.state.oldPassword,
            newPassword: this.state.newPassword
        }

        // Set the state to be in loading state
        this.setState({ loading: true, message: null });

        Requests.patch("/users/", passwordChange)
            .then(response => {
                this.setState({
                    loading: false,
                    message: { isError: false, content: t('profile.messages.passwordChangeSuccess') },
                    oldPassword: "",
                    newPassword: "",
                    repeatNewPassword: "",
                    validate: false
                });
            })
            .catch(err => {
                console.log("Caught error, ", err);
                if (err.message) {
                    this.setState({ loading: false, message: { isError: true, content: err.message } });
                }
                this.setState({ loading: false, message: { isError: true, content: t('messages.errorCheckLogs') } });
            });
    }

    getMessage = () => {
        const { t } = this.props;
        if (this.state.message) {
            if (this.state.message.isError) {
                return (
                    <Message negative className="ua-error">
                        <Message.Header>{t('app.error')}</Message.Header>
                        <p>{this.state.message.content}</p>
                    </Message>
                )
            } else {
                return (
                    <Message positive>
                        <Message.Header>{t('app.success')}</Message.Header>
                        <p>{this.state.message.content}</p>
                    </Message>
                )
            }
        }
        return null;
    }

    render() {
        const { t } = this.props;

        return (
            <Form className="ua-form padding-ten">
                <Form.Group className="hidden">
                    <Form.Field>
                        <Form.Input
                            inline
                            readOnly
                            label={t('title.email')}
                            value={this.props.userState.user.email}
                        />
                    </Form.Field>
                </Form.Group>
                <Form.Group>
                    <Form.Field>
                        <Form.Input
                            inline
                            required={true}
                            id="current-password"
                            type="password"
                            label={t('profile.passwordChange.oldPassword')}
                            value={this.state.oldPassword}
                            onChange={(e) =>
                                this.setState({ oldPassword: e.target.value, validate: true })
                            } />
                    </Form.Field>
                </Form.Group>
                <Form.Group>
                    <Form.Field>
                        <Form.Input
                            inline
                            required={true}
                            id="new-password"
                            type="password"
                            label={t('profile.passwordChange.newPassword')}
                            error={this.state.fieldErrors['password']}
                            value={this.state.newPassword}
                            onChange={(e) =>
                                this.setState({ newPassword: e.target.value, validate: true })
                            } />
                    </Form.Field>
                </Form.Group>
                <Form.Group>
                    <Form.Field>
                        <Form.Input
                            inline
                            required={true}
                            id="repeat-new-password"
                            type="password"
                            label={t('profile.passwordChange.repeatNewPassword')}
                            error={this.state.fieldErrors['repeatNewPassword']}
                            value={this.state.repeatNewPassword}
                            onChange={(e) =>
                                this.setState({ repeatNewPassword: e.target.value, validate: true })
                            } />
                    </Form.Field>
                </Form.Group>
                {this.getMessage()}
                <div className="right-side">
                    <Button
                        className="login-button"
                        onClick={this.change}
                        disabled={!this.validate()}
                        loading={this.state.loading}
                    >
                        {t('profile.passwordChange.change')}
                    </Button>
                </div>
            </Form>
        );
    }
}

const mapStateToProps = (store, props) => {
    return {
        userState: store.userState,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setUser: (user) => {

        },
        clear: () => {
        }
    };
};

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(PasswordChange);
