import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import App from './app';
import * as serviceWorker from './serviceWorker';
import { Store } from './services/store.jsx';

import 'semantic-ui-css/semantic.min.css';
import './index.scss';
import './forms.scss';
import '../src/css/all.css';

import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";

ReactDOM.render(
    <Provider store={Store.getStore()}>
        <I18nextProvider i18n={i18n}>
            <App />
        </I18nextProvider>
    </Provider>,
    document.getElementById('root'));
serviceWorker.unregister();
