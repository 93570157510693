import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { Button, Checkbox, Loader, Message, Modal, Table } from 'semantic-ui-react';
import ConfirmButton from '../common/confirmButton';
import { DataUtil } from '../common/DataUtil';
import './appHeader.scss';
import Requests from '../../services/requests';

class ArchivedScenarios extends React.Component {

    state = {
        scenarios: [],
        selectedScenarios: [],
        loading: true,
        error: null,
        open: false
    }

    setOpen = (openModal) => {
        if (openModal) {
            // fetch scenarios if modal is being opened
            this.fetchScenarios();
        }
        this.setState({ open: openModal, loading: openModal, selectedScenarios: [] });
    }

    fetchScenarios = () => {
        var { t } = this.props;

        // get archived scenarios
        let url = "/scenario/?archived=true&organisationId=" + this.props.organisationId;
        Requests.get(url)
            .then(scenarios => {
                if (scenarios) {
                    this.setState({
                        scenarios: scenarios,
                        error: null,
                        loading: false,
                        selectedScenarios: []
                    });
                } else {
                    this.setState({
                        error: t("scenario.messages.failedToFetch"),
                        loading: false
                    });
                }
            })
            .catch(err => {
                this.setState({
                    error: t("scenario.messages.failedToFetch"),
                    loading: false
                });
            })
    }

    restoreSelected = () => {
        var { t } = this.props;
        let url = "/scenario/restore/" + this.state.selectedScenarios.join();
        Requests.put(url)
            .then(resp => {
                // If successfully restored - refresh the store scenarios
                this.props.fetchScenarios();
                this.setOpen(false);
            })
            .catch(err => {
                this.setState({ error: t("scenario.archived.failedToRestore") });
            })
    }

    deleteSelected = () => {
        var { t } = this.props;

        this.setState({ loading: true });
        Requests.deleteRequest("/scenario/" + this.state.selectedScenarios.join())
            .then(resp => {
                this.props.fetchScenarios();
                this.fetchScenarios();
            })
            .catch(err => {
                this.setState({ error: t("scenario.archived.failedToDelete"), loading: false });
            })
    }

    getContent = () => {
        const { t } = this.props;
        if (this.state.loading) {
            return (
                <Loader active inline='centered' size='massive'>{t("scenario.loading")}</Loader>
            );
        }

        if (this.state.error) {
            return (
                <Message negative>
                    {this.state.error}
                </Message>
            )
        }

        // Build a list of scenarios
        if (this.state.scenarios.length === 0) {
            return (
                <p> {t("scenario.archived.noScenarios")} </p>
            );
        }

        var tableEntries = [];
        for (var ind in this.state.scenarios) {
            let scenario = this.state.scenarios[ind]; // eslint-disable-line security/detect-object-injection
            tableEntries.push(
                <Table.Row key={"archived-scenario-row-" + scenario.id}>
                    <Table.Cell collapsing>
                        <Checkbox
                            checked={this.state.selectedScenarios.includes(scenario.id)}
                            onClick={() => this.selectScenario(scenario.id)}
                        />
                    </Table.Cell>
                    <Table.Cell>{scenario.name}</Table.Cell>
                    <Table.Cell>{DataUtil.formatDate(new Date(scenario.createdDate))}</Table.Cell>
                    <Table.Cell>{DataUtil.formatDate(new Date(scenario.lastUpdatedDate))}</Table.Cell>
                    <Table.Cell>{scenario.notes}</Table.Cell>
                </Table.Row>
            );
        }

        return (
            <Table compact className="ua-table">
                <Table.Header fullWidth>
                    <Table.Row>
                        <Table.HeaderCell />
                        <Table.HeaderCell>{t("sidebar.name")}</Table.HeaderCell>
                        <Table.HeaderCell>{t("sidebar.creationDate")}</Table.HeaderCell>
                        <Table.HeaderCell>{t("sidebar.archivedDate")}</Table.HeaderCell>
                        <Table.HeaderCell>{t("sidebar.notes")}</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {tableEntries}
                </Table.Body>
            </Table>
        )
    }

    selectScenario = (id) => {
        this.setState(prevState => {
            // If this id is laready selected - deselect it
            if (prevState.selectedScenarios.includes(id)) {
                const selectedScenarios = prevState.selectedScenarios.filter(ss => ss !== id);
                return {
                    ...prevState,
                    selectedScenarios: selectedScenarios
                }
            }

            // Else add the id to selected scenarios
            return {
                ...prevState,
                selectedScenarios: [...prevState.selectedScenarios, id]
            }
        })
    }

    render() {
        const { t } = this.props;

        return (
            <Modal
                className="ua-modal"
                onClose={() => this.setOpen(false)}
                onOpen={() => this.setOpen(true)}
                open={this.state.open}
                trigger={<Button className="restore-scenarios">{t("scenario.archived.showArchived")}</Button>}
            >
                <Modal.Header>{t("scenario.archived.title")}</Modal.Header>
                <Modal.Content>
                    {this.getContent()}
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={() => this.setOpen(false)}>
                        {t("scenario.archived.back")}
                    </Button>
                    <ConfirmButton
                        buttonContent={t("scenario.archived.delete")}
                        buttonDisabled={this.state.loading || this.state.selectedScenarios.length === 0}
                        confirmHeader={t("scenario.archived.deleteAlertHeader")}
                        confirmContent={t("scenario.archived.deleteAlertBody")}
                        cancelButtonText={t("scenario.archived.cancel")}
                        confirmButtonText={t("scenario.archived.delete")}
                        onConfirm={this.deleteSelected}

                    />
                    <Button
                        content={t("scenario.archived.restoreSelected")}
                        disabled={this.state.loading || this.state.selectedScenarios.length === 0}
                        onClick={this.restoreSelected}
                        positive
                    />
                </Modal.Actions>
            </Modal>
        )
    }
}

export default compose(withTranslation())(ArchivedScenarios);
