import L from 'leaflet';
import customIcon from '../../../images/GreenSensor.svg';

const GreenDetectorIcon = new L.Icon({
    iconUrl: customIcon,
    iconRetinaUrl: customIcon,
    iconAnchor: [22, 50], // point of the icon which will correspond to marker's location
    popupAnchor: [-20, -20], // point from which the popup should open relative to the iconAnchor
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(44, 50)
});

export { GreenDetectorIcon };

