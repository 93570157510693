

import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { compose } from "redux";
import { Dimmer, Loader, Popup } from "semantic-ui-react";
import { ReactComponent as MetIcon } from '../../../images/Meteorology.svg';
import { Actions as CommonActions } from "../../../services/common/actions.jsx";

class MetLoader extends React.Component {

    state = {
        loading: true,
        error: false
    };

    componentDidMount = () => {
        // Start fetching data
        this.props.fetchMets(this.props.scenarioState.scenario.id);
    };

    componentDidUpdate(prevProps) {
        // Check fetching finished
        if (!this.props.metState.loading && prevProps.metState.loading) {
            if (this.props.metState.error) {
                // Error occurred - notify parent:
                this.props.fetchError("Met");
                this.setState({ loading: false, error: true });
            } else {
                this.props.fetchFinished("Met");
                this.setState({ loading: false, error: false });
            }
        }
    }

    render() {
        const { t } = this.props;
        var additionalClass = "";
        if (!this.state.loading) {
            if (!this.state.error) {
                additionalClass = " success";
            } else {
                additionalClass = " error";
            }
        }

        return (
            <div className="data-loader">
                <Popup
                    content={t("met.meteorology")}
                    position={"right center"}
                    size='mini'
                    trigger={
                        <div style={{ position: "relative" }}>
                            <Dimmer inverted active={this.state.loading}>
                                <Loader size='small' />
                            </Dimmer>
                            <MetIcon className={"data-loader-icon" + additionalClass} />
                        </div>
                    }
                />
            </div>
        );
    }
}

/*
* Maps state from the store to properties used by this class
*/
const mapStateToProps = (store, props) => {
    return {
        scenarioState: store.scenarioState,
        metState: store.metState,
        ...props
    }
}

/*
* Maps properties to dispatch methods to send actions to the store reducers
*/
const mapDispatchToProps = (dispatch) => {
    return {
        fetchMets: (scenarioId) => {
            dispatch(CommonActions.fetchMets(scenarioId));
        },
    }
}

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(MetLoader);