

import React from "react";
import { compose } from "redux";
import { connect } from 'react-redux';
import { Popup, Loader, Dimmer } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import { ResultsActions } from '../../../services/results/actions.jsx';
import { ReactComponent as OutputIcon } from '../../../images/Outputs.svg';

class OutputLoader extends React.Component {

    state = {
        loading: true,
        error: false
    };

    componentDidMount = () => {
        // Start fetching data
        this.props.fetchSimulationOutputs(this.props.scenarioState.scenario.id);
    };

    componentDidUpdate(prevProps) {
        // Check fetching finished
        if (!this.props.resultsState.loading && prevProps.resultsState.loading) {
            if (this.props.resultsState.error) {
                // Error occurred - notify parent:
                this.props.fetchError("Output");
                this.setState({ loading: false, error: true });
            } else {
                this.props.fetchFinished("Output");
                this.setState({ loading: false, error: false });
            }
        }
    }

    render() {
        const { t } = this.props;
        var additionalClass = "";
        if (!this.state.loading) {
            if (!this.state.error) {
                additionalClass = " success";
            } else {
                additionalClass = " error";
            }
        }

        return (
            <div className="data-loader">
                <Popup
                    content={t("sidebar.outputs")}
                    position={"right center"}
                    size='mini'
                    trigger={
                        <div style={{ position: "relative" }}>
                            <Dimmer inverted active={this.state.loading}>
                                <Loader size='small' />
                            </Dimmer>
                            <OutputIcon className={"data-loader-icon" + additionalClass} />
                        </div>
                    }
                />
            </div>
        );
    }
}

/*
* Maps state from the store to properties used by this class
*/
const mapStateToProps = (store, props) => {
    return {
        scenarioState: store.scenarioState,
        resultsState: store.resultsState,
        ...props
    }
}

/*
* Maps properties to dispatch methods to send actions to the store reducers
*/
const mapDispatchToProps = (dispatch) => {
    return {
        fetchSimulationOutputs: (scenarioId) => {
            dispatch(ResultsActions.fetchSimulationOutputs(scenarioId));
        },
    }
}

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(OutputLoader);