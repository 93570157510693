import React from "react";
import { Accordion, Icon, Table } from 'semantic-ui-react';
import { AuditCreatedUtils } from './auditCreatedUtils';

export class AuditUtils {

    /**
     * Generates an HTML component with audit description
     * @param {Audit} audit audit
     * @param {Function} t translation function
     * @returns HTML component for audit description
     */
    static getAuditDescription(audit, t, activeIndex, handleClick) {

        // Build the description translation string
        let actionType = audit.actionType.toLowerCase();
        let assetType = 'unknown';
        if (audit.auditType === 'ScenarioAudit') {
            assetType = 'scenario';
        } else if (audit.auditType === 'AreaOfInterestAudit') {
            assetType = 'area_of_interest';
        } else if (audit.auditType === 'SimulationAudit') {
            assetType = 'simulation';
        } else if (audit.auditType === 'IncidentAudit') {
            assetType = 'incident';
        } else if (audit.auditType === 'MetAudit') {
            assetType = 'met';
        } else if (audit.auditType === 'KeyBuildingAudit') {
            assetType = 'key_building';
        }

        let actionDescription = t('auditTool.descriptions.' + assetType + '_' + actionType);
        if (audit.currentObject.uiName) {
            actionDescription = actionDescription + " (" + audit.currentObject.uiName + ")";
        }

        // If the action type was CREATED use the separate util class to build content
        if (audit.actionType === 'DELETED' || audit.actionType === 'CREATED') {
            let content = AuditCreatedUtils.getCreatedAuditDescription(audit, actionDescription, t);
            return this.buildAccordionSection(audit, actionDescription, content, activeIndex, handleClick);
        }

        // Otherwise build an extenable section showing the changes made
        return this.getEditedAuditDescription(audit, actionDescription, t, activeIndex, handleClick);
    }

    static getEditedAuditDescription(audit, actionDescription, t, activeIndex, handleClick) {
        // Check which object audit is being handled
        let content = [];
        if (audit.auditType === 'ScenarioAudit') {
            content = this.getScenarioAuditDescription(audit, actionDescription, t);
        } else if (audit.auditType === 'AreaOfInterestAudit') {
            content = this.getAoiAuditDescription(audit, actionDescription, t);
        } else if (audit.auditType === 'SimulationAudit') {
            content = this.getSimulationAuditDescription(audit, actionDescription, t);
        } else if (audit.auditType === 'IncidentAudit') {
            content = this.getIncidentAuditDescription(audit, actionDescription, t);
        } else if (audit.auditType === 'MetAudit') {
            content = this.getMetAuditDescription(audit, actionDescription, t);
        } else if (audit.auditType === 'KeyBuildingAudit') {
            content = this.getBuildingAuditDescription(audit, actionDescription, t);
        }

        return this.buildAccordionSection(audit, actionDescription, content, activeIndex, handleClick);
    }

    static getScenarioAuditDescription(audit, actionDescription, t) {
        let tableRows = [];
        // Check which fields on the scenario have been edited
        if (audit.currentObject && audit.previousObject) {
            if (audit.currentObject.name !== audit.previousObject.name) {
                // Scenario name has been changed, add it to table
                this.populateRowArray(tableRows, t('scenario.scenarioName'), audit.previousObject.name, audit.currentObject.name, audit);
            }
            if (audit.currentObject.notes !== audit.previousObject.notes) {
                this.populateRowArray(tableRows, t('label.notes'), audit.previousObject.notes, audit.currentObject.notes, audit);
            }
            if (audit.currentObject.archived !== audit.previousObject.archived) {
                this.populateRowArray(tableRows, t('label.archived'), audit.previousObject.archived, audit.currentObject.archived, audit);
            }

        }

        return (
            <Table celled striped className="audit-change-table">
                {tableRows}
            </Table>
        );
    }

    static getAoiAuditDescription(audit, actionDescription, t) {

        let tableRows = [];
        if (audit.currentObject && audit.previousObject) {
            if (audit.currentObject.name !== audit.previousObject.name) {
                this.populateRowArray(tableRows, t('label.name'), audit.previousObject.name, audit.currentObject.name, audit);
            }
            if (audit.currentObject.aoiType !== audit.previousObject.aoiType) {
                this.populateRowArray(tableRows, t('sidebar.type'), audit.previousObject.aoiType, audit.currentObject.aoiType, audit);
            }
            if (audit.currentObject.notes !== audit.previousObject.notes) {
                this.populateRowArray(tableRows, t('label.notes'), audit.previousObject.notes, audit.currentObject.notes, audit);
            }
        }

        return (
            <Table celled striped className="audit-change-table">
                {tableRows}
            </Table>
        );
    }

    static getSimulationAuditDescription(audit, actionDescription, t) {

        let tableRows = [];
        if (audit.currentObject && audit.previousObject) {
            if (audit.currentObject.name !== audit.previousObject.name) {
                this.populateRowArray(tableRows, t('label.name'), audit.previousObject.name, audit.currentObject.name, audit);
            }
            if (audit.currentObject.archived !== audit.previousObject.archived) {
                this.populateRowArray(tableRows, t('label.archived'), audit.previousObject.archived, audit.currentObject.archived, audit);
            }
            if (audit.currentObject.modelDuration !== audit.previousObject.modelDuration) {
                this.populateRowArray(tableRows, t('simulation.modelDuration'), audit.previousObject.modelDuration, audit.currentObject.modelDuration, audit);
            }
            if (audit.currentObject.outputInterval !== audit.previousObject.outputInterval) {
                this.populateRowArray(tableRows, t('simulation.outputInterval'), audit.previousObject.outputInterval, audit.currentObject.outputInterval, audit);
            }
            if (audit.currentObject.gridResolution !== audit.previousObject.gridResolution) {
                this.populateRowArray(tableRows, t('simulation.gridResolution'), audit.previousObject.gridResolution, audit.currentObject.gridResolution, audit);
            }
            if (audit.currentObject.buildingInteractionsOption !== audit.previousObject.buildingInteractionsOption) {
                let prevBuildingMode = t('simulation.buildingModes.' + audit.previousObject.buildingInteractionsOption);
                let currBuildingMode = t('simulation.buildingModes.' + audit.currentObject.buildingInteractionsOption);
                this.populateRowArray(tableRows, t('simulation.buildingInteractionsOption'), prevBuildingMode, currBuildingMode, audit);
            }
            if (audit.currentObject.status !== audit.previousObject.status) {
                let prevStatus = t('simulation.runStatus.' + audit.previousObject.status);
                let currStatus = t('simulation.runStatus.' + audit.currentObject.status);
                this.populateRowArray(tableRows, t('sidebar.status'), prevStatus, currStatus, audit);
            }
            if (audit.currentObject.metSourceType !== audit.previousObject.metSourceType) {
                this.populateRowArray(tableRows, t('met.metSourceType'), audit.previousObject.metSourceType, audit.currentObject.metSourceType, audit);
            }
            if (audit.currentObject.metServiceName !== audit.previousObject.metServiceName) {
                this.populateRowArray(tableRows, t('met.metServiceProvider'), audit.previousObject.metServiceName, audit.currentObject.metServiceName, audit);
            }

        }

        return (
            <Table celled striped className="audit-change-table">
                {tableRows}
            </Table>
        );
    }

    static getIncidentAuditDescription(audit, actionDescription, t) {

        let tableRows = [];
        if (audit.currentObject && audit.previousObject) {
            if (audit.currentObject.name !== audit.previousObject.name) {
                this.populateRowArray(tableRows, t('label.name'), audit.previousObject.name, audit.currentObject.name, audit);
            }
            if (audit.currentObject.startTime !== audit.previousObject.startTime) {
                this.populateRowArray(tableRows, t('label.startTime'), this.formatTime(audit.previousObject.startTime), this.formatTime(audit.currentObject.startTime), audit);
            }
            if (audit.currentObject.materialName !== audit.previousObject.materialName) {
                this.populateRowArray(tableRows, t('label.material'), audit.previousObject.materialName, audit.currentObject.materialName, audit);
            }
            if (audit.currentObject.mass !== audit.previousObject.mass) {
                this.populateRowArray(tableRows, t('label.mass'), audit.previousObject.mass, audit.currentObject.mass, audit);
            }
            if (audit.currentObject.duration !== audit.previousObject.duration) {
                this.populateRowArray(tableRows, t('label.duration'), audit.previousObject.duration, audit.currentObject.duration, audit);
            }
            if (audit.currentObject.radius !== audit.previousObject.radius) {
                this.populateRowArray(tableRows, t('sidebar.radius'), audit.previousObject.radius, audit.currentObject.radius, audit);
            }
            if (audit.currentObject.sigmaX !== audit.previousObject.sigmaX) {
                this.populateRowArray(tableRows, t('label.sigmaX'), audit.previousObject.sigmaX, audit.currentObject.sigmaX, audit);
            }
            if (audit.currentObject.sigmaY !== audit.previousObject.sigmaY) {
                this.populateRowArray(tableRows, t('label.sigmaY'), audit.previousObject.sigmaY, audit.currentObject.sigmaY, audit);
            }
            if (audit.currentObject.sigmaZ !== audit.previousObject.sigmaZ) {
                this.populateRowArray(tableRows, t('label.sigmaZ'), audit.previousObject.sigmaZ, audit.currentObject.sigmaZ, audit);
            }
            if (audit.currentObject.meanParticleDropletSize !== audit.previousObject.meanParticleDropletSize) {
                // Format the value
                let prevValue = Number.parseFloat(audit.previousObject.meanParticleDropletSize).toFixed(6);
                let currValue = Number.parseFloat(audit.currentObject.meanParticleDropletSize).toFixed(6);
                this.populateRowArray(tableRows, t('label.meanParticleDropletSize'), prevValue, currValue, audit);
            }
            if (audit.currentObject.stdDevParticleDropletSize !== audit.previousObject.stdDevParticleDropletSize) {
                // Format the value
                let prevValue = Number.parseFloat(audit.previousObject.stdDevParticleDropletSize).toFixed(6);
                let currValue = Number.parseFloat(audit.currentObject.stdDevParticleDropletSize).toFixed(6);
                this.populateRowArray(tableRows, t('label.stdDevDropletSize'), prevValue, currValue, audit);
            }
            if (audit.currentObject.tntMass !== audit.previousObject.tntMass) {
                this.populateRowArray(tableRows, t('label.tntMass'), audit.previousObject.tntMass, audit.currentObject.tntMass, audit);
            }

        }

        return (
            <Table celled striped className="audit-change-table">
                {tableRows}
            </Table>
        );
    }

    static getMetAuditDescription(audit, actionDescription, t) {
        let tableRows = [];
        if (audit.currentObject && audit.previousObject) {
            if (audit.currentObject.time !== audit.previousObject.time) {
                this.populateRowArray(tableRows, t('met.time'), this.formatTime(audit.previousObject.time), this.formatTime(audit.currentObject.time), audit);
            }
            if (audit.currentObject.windSpeed !== audit.previousObject.windSpeed) {
                this.populateRowArray(tableRows, t('met.windSpeed'), audit.previousObject.windSpeed, audit.currentObject.windSpeed, audit);
            }
            if (audit.currentObject.referenceHeight !== audit.previousObject.referenceHeight) {
                this.populateRowArray(tableRows, t('met.referenceHeight'), audit.previousObject.referenceHeight, audit.currentObject.referenceHeight, audit);
            }
            if (audit.currentObject.windDirection !== audit.previousObject.windDirection) {
                this.populateRowArray(tableRows, t('met.windDirection'), audit.previousObject.windDirection, audit.currentObject.windDirection, audit);
            }
            if (audit.currentObject.temperature !== audit.previousObject.temperature) {
                this.populateRowArray(tableRows, t('met.temperature'), audit.previousObject.temperature, audit.currentObject.temperature, audit);
            }
            if (audit.currentObject.stability !== audit.previousObject.stability) {
                this.populateRowArray(tableRows, t('met.stability'), audit.previousObject.stability, audit.currentObject.stability, audit);
            }

        }

        return (
            <Table celled striped className="audit-change-table">
                {tableRows}
            </Table>
        );
    }

    static getBuildingAuditDescription(audit, actionDescription, t) {
        // At the moment key buildings are not editable, so nothing to return here
        let tableRows = [];
        if (audit.currentObject && audit.previousObject) {
            if (audit.currentObject.name !== audit.previousObject.name) {
                this.populateRowArray(tableRows, t('sidebar.name'), audit.previousObject.name, audit.currentObject.name, audit);
            }
            if (audit.currentObject.notes !== audit.previousObject.notes) {
                this.populateRowArray(tableRows, t('sidebar.notes'), audit.previousObject.notes, audit.currentObject.notes, audit);
            }
            if (audit.currentObject.buildingType !== audit.previousObject.buildingType) {
                this.populateRowArray(tableRows, t('sidebar.bvimBuildingType'), audit.previousObject.buildingType, audit.currentObject.buildingType, audit);
            }
            if (audit.currentObject.bvimModelType !== audit.previousObject.bvimModelType) {
                let prevType = t('keyBuild.bvimModelTypes.' + audit.previousObject.bvimModelType);
                let currType = t('keyBuild.bvimModelTypes.' + audit.currentObject.bvimModelType);
                this.populateRowArray(tableRows, t('sidebar.bvimModelType'), prevType, currType, audit);
            }
        }

        return (
            <Table celled striped className="audit-change-table">
                {tableRows}
            </Table>
        );
    }

    static populateRowArray(rowArray, header, previousValue, currentValue, audit) {
        // Push a row for header
        rowArray.push(
            <Table.Header key={header + "-" + audit.id}>
                <Table.Row>
                    <Table.HeaderCell colSpan={2}>{header}</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
        );

        // Push a row for the value change
        rowArray.push(
            <Table.Body key={"body-" + header + "-" + audit.id}>
                <Table.Row>
                    <Table.Cell className="prev-value-cell">{`${previousValue}`}</Table.Cell>
                    <Table.Cell>{`${currentValue}`}</Table.Cell>
                </Table.Row>
            </Table.Body>
        );
    }

    static formatTime(value) {
        return new Date(value).toLocaleString();
    }

    static buildAccordionSection(audit, actionDescription, content, activeIndex, handleClick) {
        // The delete actions are not expandable, as they have no further information to show
        let handleTitleClick = handleClick;
        let dropdownIcon = <Icon name='dropdown' />;
        if (content.length === 0) {
            handleTitleClick = null;
            dropdownIcon = null;
        }
        return (
            <React.Fragment key={"audit-" + audit.id}>
                <Accordion.Title
                    active={activeIndex === audit.id}
                    index={audit.id}
                    onClick={handleTitleClick}
                >
                    <div className="audit-header">
                        <div className="audit-time">{new Date(audit.time).toLocaleTimeString([], { hour: '2-digit', minute: "2-digit", hour12: false })}</div>
                        <div className="audit-user">{audit.userName}</div>
                    </div>
                    {dropdownIcon}
                    {actionDescription}
                </Accordion.Title>
                <Accordion.Content active={activeIndex === audit.id}>
                    {content}
                </Accordion.Content>
            </React.Fragment>
        );
    }
}