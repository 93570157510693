import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { Tab } from "semantic-ui-react";
import PanelWrapper from "../../common/panelWrapper.jsx";
import "../aois/styles.scss";
import "../sidebar.scss";
import OutputOptions from "./outputOptions/OutputOptions.jsx";
import RunOptions from "./runOptions/runOptions.jsx";
import { IncidentsUtils } from "../../utils/IncidentsUtils.jsx";

/**
 * View to allow user to edit simulation and output settings and run it
 */
class RunSimulationPanel extends React.Component {

  getTabs = () => {
    const { t } = this.props;
    const tabs = [];

    const incidentsForCurrentSimulation = this.props.incidentState.incidents.filter(
      inc => inc.simulationId == this.props.simulationState.selectedSimulation.id
    );

    let modelTypes = IncidentsUtils.getRequiredModels(incidentsForCurrentSimulation);
    if (modelTypes.includes('UDM')) {
      tabs.push({ menuItem: t('configurations.title.runOptions'), render: () => <Tab.Pane><RunOptions /></Tab.Pane> });
    }
    tabs.push({ menuItem: t('configurations.title.outputOptions'), render: () => <Tab.Pane><OutputOptions /></Tab.Pane> });


    return tabs;
  }

  render() {
    const { t } = this.props;
    return (
      <PanelWrapper
        key={"runsim" + this.props.simulationState.selectedSimulation.id}
        title={this.props.title}
        cancel={this.props.cancel}
      >
        <Tab className="ua-tab" panes={this.getTabs()} />
      </PanelWrapper>
    );
  }
}

/*
 * Maps state from the store to properties used by this class
 */
const mapStateToProps = (store, props) => {
  return {
    simulationState: store.simulationState,
    contourConfigState: store.contourConfigState,
    metState: store.metState,
    incidentState: store.incidentState,
    resultsState: store.resultsState,
    ...props,
  };
};

export default compose(
  withTranslation(),
  connect(mapStateToProps)
)(RunSimulationPanel);
