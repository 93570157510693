import L from 'leaflet';

/**
 * A leaflet wrapper class that expects an icon url, and returns a Leaflet Icon
 * object that can be used in React Leaflet components
 * @param {String} iconUrl the url of the icon
 * @returns Leaflet Icon object
 */
export function CustomIcon (iconUrl) {
    return new L.Icon({
        iconUrl: iconUrl,
        iconRetinaUrl: iconUrl,
        iconAnchor: [25, 48], // point of the icon which will correspond to marker's location
        popupAnchor: [1, -45], // point from which the popup should open relative to the iconAnchor
        shadowUrl: null,
        shadowSize: null,
        shadowAnchor: null,
        iconSize: new L.Point(50, 50)
    });
}