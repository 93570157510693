import { Table } from 'semantic-ui-react';

export class AuditCreatedUtils {

    static getCreatedAuditDescription(audit, actionDescription, t) {
        // Check which object audit is being handled
        let content = [];
        if (audit.auditType === 'ScenarioAudit') {
            content = this.getScenarioAuditDescription(audit, actionDescription, t);
        } else if (audit.auditType === 'AreaOfInterestAudit') {
            content = this.getAoiAuditDescription(audit, actionDescription, t);
        } else if (audit.auditType === 'SimulationAudit') {
            content = this.getSimulationAuditDescription(audit, actionDescription, t);
        } else if (audit.auditType === 'IncidentAudit') {
            content = this.getIncidentAuditDescription(audit, actionDescription, t);
        } else if (audit.auditType === 'MetAudit') {
            content = this.getMetAuditDescription(audit, actionDescription, t);
        } else if (audit.auditType === 'KeyBuildingAudit') {
            content = this.getBuildingAuditDescription(audit, actionDescription, t);
        }

        return content;
    }

    static getScenarioAuditDescription(audit, actionDescription, t) {
        let tableRows = [];
        if (audit.currentObject) {
            if (audit.currentObject.name != null) {
                this.populateRowArray(tableRows, t('scenario.scenarioName'), audit.currentObject.name, audit);
            }
            if (audit.currentObject.notes != null) {
                this.populateRowArray(tableRows, t('label.notes'), audit.currentObject.notes, audit);
            }
            if (audit.currentObject.archived != null) {
                this.populateRowArray(tableRows, t('label.archived'), audit.currentObject.archived, audit);
            }
        }

        return (
            <Table celled striped className="audit-change-table">
                {tableRows}
            </Table>
        );
    }

    static getAoiAuditDescription(audit, actionDescription, t) {

        let tableRows = [];
        if (audit.currentObject) {
            if (audit.currentObject.name != null) {
                this.populateRowArray(tableRows, t('label.name'), audit.currentObject.name, audit);
            }
            if (audit.currentObject.aoiType != null) {
                this.populateRowArray(tableRows, t('sidebar.type'), audit.currentObject.aoiType, audit);
            }
            if (audit.currentObject.notes != null) {
                this.populateRowArray(tableRows, t('label.notes'), audit.currentObject.notes, audit);
            }
        }

        return (
            <Table celled striped className="audit-change-table">
                {tableRows}
            </Table>
        );
    }

    static getSimulationAuditDescription(audit, actionDescription, t) {

        let tableRows = [];
        if (audit.currentObject) {
            if (audit.currentObject.name != null) {
                this.populateRowArray(tableRows, t('label.name'), audit.currentObject.name, audit);
            }
            if (audit.currentObject.archived != null) {
                this.populateRowArray(tableRows, t('label.archived'), audit.currentObject.archived, audit);
            }
            if (audit.currentObject.modelDuration != null) {
                this.populateRowArray(tableRows, t('simulation.modelDuration'), audit.currentObject.modelDuration, audit);
            }
            if (audit.currentObject.outputInterval != null) {
                this.populateRowArray(tableRows, t('simulation.outputInterval'), audit.currentObject.outputInterval, audit);
            }
            if (audit.currentObject.gridResolution != null) {
                this.populateRowArray(tableRows, t('simulation.gridResolution'), audit.currentObject.gridResolution, audit);
            }
            if (audit.currentObject.buildingInteractionsOption != null) {
                let currBuildingMode = t('simulation.buildingModes.' + audit.currentObject.buildingInteractionsOption);
                this.populateRowArray(tableRows, t('simulation.buildingInteractionsOption'), currBuildingMode, audit);
            }
            if (audit.currentObject.metSourceType != null) {
                this.populateRowArray(tableRows, t('met.metSourceType'), audit.currentObject.metSourceType, audit);
            }
            if (audit.currentObject.metServiceName != null) {
                this.populateRowArray(tableRows, t('met.metServiceProvider'), audit.currentObject.metServiceName, audit);
            }

        }

        return (
            <Table celled striped className="audit-change-table">
                {tableRows}
            </Table>
        );
    }

    static getIncidentTypeTranslation(incidentType) {
        switch (incidentType) {
            case "POINT":
                return "sidebar.PointSource";
            case "LINE":
                return "sidebar.LineSource";
            case "MOVING":
                return "sidebar.MovingSource";
            case "LIQUID_POOL":
                return "sidebar.PoolSource";
            case "Explosive":
                return "sidebar.explosive";
            case "Tear Gas Canister":
                return "sidebar.teargas";
            case "Template":
                    return "sidebar.atp45";
            default:
                return "";
        }
    }

    static getIncidentAuditDescription(audit, actionDescription, t) {

        let tableRows = [];
        if (audit.currentObject) {
            if (audit.currentObject.name != null) {
                this.populateRowArray(tableRows, t('label.name'), audit.currentObject.name, audit);
            }
            if (audit.currentObject.incidentType != null) {
                // Determine the translation for this, as this is not an enum
                let incidentTypeTransString = this.getIncidentTypeTranslation(audit.currentObject.incidentType);
                this.populateRowArray(tableRows, t('sidebar.type'), t(incidentTypeTransString), audit);
            }
            if (audit.currentObject.startTime != null) {
                this.populateRowArray(tableRows, t('label.startTime'), this.formatTime(audit.currentObject.startTime), audit);
            }
            if (audit.currentObject.materialName != null) {
                this.populateRowArray(tableRows, t('label.material'), audit.currentObject.materialName, audit);
            }
            if (audit.currentObject.mass != null) {
                this.populateRowArray(tableRows, t('label.mass'), audit.currentObject.mass, audit);
            }
            if (audit.currentObject.duration != null) {
                this.populateRowArray(tableRows, t('label.duration'), audit.currentObject.duration, audit);
            }
            if (audit.currentObject.radius != null) {
                this.populateRowArray(tableRows, t('sidebar.radius'), audit.currentObject.radius, audit);
            }
            if (audit.currentObject.sigmaX != null) {
                this.populateRowArray(tableRows, t('label.sigmaX'), audit.currentObject.sigmaX, audit);
            }
            if (audit.currentObject.sigmaY != null) {
                this.populateRowArray(tableRows, t('label.sigmaY'), audit.currentObject.sigmaY, audit);
            }
            if (audit.currentObject.sigmaZ != null) {
                this.populateRowArray(tableRows, t('label.sigmaZ'), audit.currentObject.sigmaZ, audit);
            }
            if (audit.currentObject.meanParticleDropletSize != null) {
                // Format the value
                let currValue = Number.parseFloat(audit.currentObject.meanParticleDropletSize).toFixed(6);
                this.populateRowArray(tableRows, t('label.meanParticleDropletSize'), currValue, audit);
            }
            if (audit.currentObject.stdDevParticleDropletSize != null) {
                // Format the value
                let currValue = Number.parseFloat(audit.currentObject.stdDevParticleDropletSize).toFixed(6);
                this.populateRowArray(tableRows, t('label.stdDevDropletSize'), currValue, audit);
            }
            if (audit.currentObject.tntMass != null) {
                this.populateRowArray(tableRows, t('label.tntMass'), audit.currentObject.tntMass, audit);
            }

        }

        return (
            <Table celled striped className="audit-change-table">
                {tableRows}
            </Table>
        );
    }

    static getMetAuditDescription(audit, actionDescription, t) {
        let tableRows = [];
        if (audit.currentObject) {
            if (audit.currentObject.time != null) {
                this.populateRowArray(tableRows, t('met.time'), this.formatTime(audit.currentObject.time), audit);
            }
            if (audit.currentObject.windSpeed != null) {
                this.populateRowArray(tableRows, t('met.windSpeed'), audit.currentObject.windSpeed, audit);
            }
            if (audit.currentObject.referenceHeight != null) {
                this.populateRowArray(tableRows, t('met.referenceHeight'), audit.currentObject.referenceHeight, audit);
            }
            if (audit.currentObject.windDirection != null) {
                this.populateRowArray(tableRows, t('met.windDirection'), audit.currentObject.windDirection, audit);
            }
            if (audit.currentObject.temperature != null) {
                this.populateRowArray(tableRows, t('met.temperature'), audit.currentObject.temperature, audit);
            }
            if (audit.currentObject.stability != null) {
                this.populateRowArray(tableRows, t('met.stability'), audit.currentObject.stability, audit);
            }

        }

        return (
            <Table celled striped className="audit-change-table">
                {tableRows}
            </Table>
        );
    }

    static getBuildingAuditDescription(audit, actionDescription, t) {
        let tableRows = [];
        if (audit.currentObject) {
            if (audit.currentObject.name != null) {
                this.populateRowArray(tableRows, t('label.name'), audit.currentObject.name, audit);
            }
            if (audit.currentObject.height != null) {
                this.populateRowArray(tableRows, t('keyBuild.height'), audit.currentObject.height, audit);
            }
            if (audit.currentObject.buildingType != null) {
                this.populateRowArray(tableRows, t('keyBuild.bvimBuildingType'), audit.currentObject.buildingType, audit);
            }
            if (audit.currentObject.bvimModelType != null) {
                this.populateRowArray(tableRows, t('keyBuild.bvimModelType'), t('keyBuild.bvimModelTypes.' + audit.currentObject.bvimModelType), audit);
            }
            if (audit.currentObject.notes != null) {
                this.populateRowArray(tableRows, t('label.notes'), audit.currentObject.notes, audit);
            }
        }

        return (
            <Table celled striped className="audit-change-table">
                {tableRows}
            </Table>
        );
    }

    static formatTime(value) {
        return new Date(value).toLocaleString();
    }

    static populateRowArray(rowArray, header, currentValue, audit) {
        // Push a row for header
        rowArray.push(
            <Table.Header key={header + "-" + audit.id}>
                <Table.Row>
                    <Table.HeaderCell>{header}</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
        );

        // Push a row for the value
        rowArray.push(
            <Table.Body key={"body-" + header + "-" + audit.id}>
                <Table.Row>
                    <Table.Cell>{`${currentValue}`}</Table.Cell>
                </Table.Row>
            </Table.Body>
        );
    }
}