/*
    ./client/components/App.jsx
*/
import L from 'leaflet';
import React from "react";
import { withTranslation } from "react-i18next";
import { FeatureGroup, GeoJSON, LayersControl, Pane } from 'react-leaflet';
import { connect } from "react-redux";
import { compose } from "redux";
import { MapLayerStyles } from '../../common/MapLayerStyles.jsx';
import { SourceStyles } from "../../common/SourceStyles";

const { BaseLayer, Overlay } = LayersControl

class SourceLayer extends Overlay {

    getLayers = () => {
        var { t } = this.props;
        var markers = [];

        if (!this.props.simulationState.selectedSimulation) {
            return markers;
        }

        var editing = this.props.incidentState.editingIncident;
        var hiddenIds = this.props.mapFilterState.hiddenLayerIds;

        var simulationId = this.props.simulationState.selectedSimulation.id;
        var sources = [];
        Object.values(this.props.incidentState.incidents).forEach(inc => {
            if (inc.simulationId === simulationId) {
                // If incident doesn't have a geoJson property - it's not a complex incident
                // and sources can be renderer separately
                if (!inc.geoJson) {
                    sources = sources.concat(inc.sources.map(source => {
                        source.incidentName = inc.name;
                        return source;
                    }));
                }
            }
        });

        for (var s in sources) {
            var source = sources[s]; // eslint-disable-line security/detect-object-injection
            if (editing && source.id === editing.id || hiddenIds.includes(source.id)) {
                continue;
            }
            if (source.geoJson.geometry.type === 'Point' && !source.radius) {
                // this source is just a marker
                markers.push(SourceStyles.getMarker(source, t, this.props.userState.user.preferences));
            } else {
                // This source will have a geoJson layer and a marker
                var mainLayer = MapLayerStyles.getNonPointGeoJson(source, SourceStyles.getStyle, "source-pane");
                // Create a JS leaflet layer, in order to get its center location to put a marker to
                var leafLayer = new L.GeoJSON(source.geoJson.geometry);
                var center = leafLayer.getBounds().getCenter();
                if (source.geoJson.geometry.type === 'LineString') {
                    var lngLat = source.geoJson.geometry.coordinates[0];
                    center = [lngLat[1], lngLat[0]];
                }
                var markerLayer = SourceStyles.getMarker(source, t, this.props.userState.user.preferences);
                markers.push(mainLayer)
                markers.push(markerLayer)
            }
        }

        if (editing && editing.geoJson) {
            markers.push(<GeoJSON key={editing.id + "EDIT" + editing.lastUpdated}
                data={editing.geoJson}
                style={MapLayerStyles.getLightEditingStyle()}
                pointToLayer={(feature, latlng) => {
                    if (feature.properties.radius) {
                        return new L.Circle(latlng, feature.properties.radius).bindTooltip(feature.properties.poiType);
                    }
                    if (feature.geometry.type === 'Point') {
                        return new L.circleMarker(latlng);
                    }
                    return;
                }}
            />);
        }

        return markers;
    }

    render() {
        return (
            <FeatureGroup>
                <Pane name="source-pane" style={{ zIndex: 198 }}>
                    {this.getLayers()}
                </Pane>
            </FeatureGroup>
        );
    }

    componentWillUnmount() {
        console.log("source use will unmount")
    }
}

/*
 * Maps state from the store to properties used by this class
 */
const mapStateToProps = (store, props) => {
    return {
        incidentState: store.incidentState,
        simulationState: store.simulationState,
        mapFilterState: store.mapFilterState,
        userState: store.userState,
        ...props,
    };
};

/*
 * Maps properties to dispatch methods to send actions to the store reducers
 */
const mapDispatchToProps = (dispatch) => {
    return {};
}

export default compose(
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps)
)(SourceLayer);
