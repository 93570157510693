import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { Button, Message, Form, Table, Icon, Popup } from "semantic-ui-react";
import ConfirmButton from '../../common/confirmButton';
import Requests from '../../../services/requests';
import "../appHeader.scss";
import { ReactComponent as ArchiveIcon } from '../../../images/Archive.svg';

class AdminPanel extends React.Component {

    state = {
        users: [],
        error: null
    };

    componentDidMount() {
        const { t } = this.props;
        if (this.props.userState.user && this.props.userState.user.role === "ROLE_ADMIN") {
            Requests.get('/users/?organisationId=' + this.props.userState.user.organisation.id)
                .then(response => {
                    if (response) {
                        this.setState({ users: response });
                    }
                })
                .catch(err => {
                    console.log("Caught error, ", err);
                    this.setState({ error: t('messages.errorCheckLogs') });
                });
        }
    }

    getUsersTable = () => {
        const { t } = this.props;
        if (this.state.users.length > 0) {
            return (
                <Table celled structured>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell >
                                {t("title.email")}
                            </Table.HeaderCell>
                            <Table.HeaderCell >
                                {t("title.displayName")}
                            </Table.HeaderCell>
                            <Table.HeaderCell >
                                {t("title.lastLogin")}
                            </Table.HeaderCell>
                            <Table.HeaderCell >
                                {t("profile.action")}
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>{this.getRows()}</Table.Body>
                </Table>
            );
        }
    }

    getRows = () => {
        const { t } = this.props;
        let userRows = [];

        for (var m in this.state.users) {
            let ind = m;
            let user = this.state.users[m]; // eslint-disable-line security/detect-object-injection
            // If the user is admin - skip it
            if (user.role === 'ROLE_ADMIN') {
                continue;
            }
            let lastLogin = "-";
            if (user.lastLogin) {
                lastLogin = new Date(user.lastLogin).toLocaleString();
            }
            userRows.push(
                <Table.Row
                    key={ind}
                >
                    <Table.Cell>{user.email}</Table.Cell>
                    <Table.Cell>{user.displayName}</Table.Cell>
                    <Table.Cell>{lastLogin}</Table.Cell>
                    <Table.Cell>
                        {this.getActionsForUser(ind, user)}
                    </Table.Cell>
                </Table.Row>
            );
        }
        return userRows;
    }

    getActionsForUser = (ind, user) => {
        const { t } = this.props;

        // If user is ARCHIVED - just display them as Archived
        if (user.status === 'ARCHIVED') {
            return t("profile.archived");
        }

        // If the user is REGISTERED - allow Approving them or Deleting their registration request
        if (user.status === 'REGISTERED') {
            return (
                <>
                    <Popup
                        className="popup"
                        content={t("profile.approve")}
                        trigger={
                            <Button onClick={(e) => this.approveUser(ind, user)}>
                                <Icon size='large' name='user plus' />
                            </Button>
                        }
                        position={"bottom center"}
                    />
                    <ConfirmButton
                        buttonContent={<Icon size='large' name='remove user' />}
                        buttonDisabled={false}
                        popupText={t("profile.reject")}
                        confirmHeader={t("profile.messages.rejectAlertHeader")}
                        confirmContent={t("profile.messages.rejectAlertBody")}
                        cancelButtonText={t("scenario.archived.cancel")}
                        confirmButtonText={t("profile.reject")}
                        onConfirm={() => this.deleteUser(ind, user)}
                    />
                </>
            );
        }

        // All other status will allow Archiving, so handle 
        let actions = [];
        // If the user is APPROVED or ENABLED - they can be either DISABLED or ARCHIVED
        if (user.status === 'APPROVED' || user.status === 'ENABLED') {
            actions.push(<Popup
                className="popup"
                content={t("profile.deactivate")}
                trigger={
                    <Button onClick={(e) => this.disableUser(ind, user)}>
                        <Icon size='large' name='user outline' />
                    </Button>
                }
                position={"bottom center"}
            />);
        }
        // If the user is DISABLED - they can be ENABLED or ARCHIVED
        else if (user.status === 'DISABLED') {
            actions.push(<Popup
                className="popup"
                content={t("profile.activate")}
                trigger={
                    <Button onClick={(e) => this.enableUser(ind, user)}>
                        <Icon size='large' name='user' />
                    </Button>
                }
                position={"bottom center"}
            />);
        }
        actions.push(<ConfirmButton
            buttonContent={<ArchiveIcon fill="white" stroke="white" style={{ width: '18px', height: '22px' }} />}
            buttonDisabled={false}
            popupText={t("profile.archive")}
            confirmHeader={t("profile.messages.archiveAlertHeader")}
            confirmContent={t("profile.messages.archiveAlertBody")}
            cancelButtonText={t("scenario.archived.cancel")}
            confirmButtonText={t("profile.archive")}
            onConfirm={() => this.archiveUser(ind, user)}
        />);
        return actions;
    }

    getOrganisationInformation = (t) => {
        // Number of available licenses is calculated by subtracting the number of users that are not archived from userLimit. Archived accounts are not counted as 
        // license holders as they cannoted be reactivated by the UI
        let availableLicenses = this.props.userState.user.organisation.userLimit - this.state.users.map(user => user.status).filter(status => status !== 'ARCHIVED').length
        return (
            <Form className="ua-form">
                <Form.Group>
                    <Form.Field>
                        <Form.Input
                            inline
                            readOnly
                            label={t('profile.availableLicenses')}
                            value={availableLicenses + "/" + this.props.userState.user.organisation.userLimit}
                        />
                    </Form.Field>
                </Form.Group>
                <Form.Group>
                    <Form.Field>
                        <Form.Input
                            inline
                            readOnly
                            label={t('title.organisationKey')}
                            value={this.props.userState.user.organisation.key}
                        />
                    </Form.Field>
                </Form.Group>
            </Form>
        )
    }

    enableUser = (ind, user) => {
        let userStatusPatch = {
            'type': 'UserStatus',
            'id': user.id,
            'status': 'ENABLED'
        }
        this.patchUser(userStatusPatch, ind);
    }

    disableUser = (ind, user) => {
        let userStatusPatch = {
            'type': 'UserStatus',
            'id': user.id,
            'status': 'DISABLED'
        }
        this.patchUser(userStatusPatch, ind);
    }

    approveUser = (ind, user) => {
        let userStatusPatch = {
            'type': 'UserStatus',
            'id': user.id,
            'status': 'APPROVED'
        }
        this.patchUser(userStatusPatch, ind);
    }

    deleteUser = (ind, user) => {
        Requests.deleteRequest('/users/' + user.id)
            .then(response => {
                let users = this.state.users.filter(u => u.id !== user.id);
                this.setState({ error: null, users: users });
            })
            .catch(err => {
                this.setState({ error: 'Failed to delete user.' });
            })
    }

    archiveUser = (ind, user) => {
        let userStatusPatch = {
            'type': 'UserStatus',
            'id': user.id,
            'status': 'ARCHIVED'
        }
        this.patchUser(userStatusPatch, ind);
    }

    patchUser = (userDto, ind) => {
        Requests.patch('/users/', userDto)
            .then(response => {
                let users = [...this.state.users];
                // ind comes from for loop
                users[ind].status = userDto.status; // eslint-disable-line security/detect-object-injection
                this.setState({ error: null, users: users });
            })
            .catch(err => {
                this.setState({ error: 'Failed to update user.' });
            })
    }

    render() {
        const { t } = this.props;
        return (
            <div className="user-window login-actions-content padding-ten">
                {this.getOrganisationInformation(t)}
                <div className="sources-table users-table">
                    {this.getUsersTable()}
                </div>
                {this.state.error ?
                    (
                        <Message negative className="ua-error">
                            <Message.Header>{t('app.error')}</Message.Header>
                            <p>{this.state.error}</p>
                        </Message>
                    ) : null
                }
            </div>
        );
    }
}

const mapStateToProps = (store, props) => {
    return {
        userState: store.userState,
    }
}

export default compose(withTranslation(), connect(mapStateToProps))(AdminPanel);
