

import React from "react";
import { compose } from "redux";
import { connect } from 'react-redux';
import { Popup, Loader, Dimmer } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import { Actions as IncidentActions } from "../../../services/incident/actions.jsx";
import { ReactComponent as IncidentIcon } from '../../../images/Warning.svg';

class SourceLoader extends React.Component {

    state = {
        loading: true,
        error: false
    };

    componentDidMount = () => {
        // Start fetching data
        this.props.fetchIncidents(this.props.scenarioState.scenario.id);
    };

    componentDidUpdate(prevProps) {
        // Check fetching finished
        if (!this.props.incidentState.loading && prevProps.incidentState.loading) {
            if (this.props.incidentState.error) {
                // Error occurred - notify parent:
                this.props.fetchError("Incident");
                this.setState({ loading: false, error: true });
            } else {
                this.props.fetchFinished("Incident");
                this.setState({ loading: false, error: false });
            }
        }
    }

    render() {
        const { t } = this.props;
        var additionalClass = "";
        if (!this.state.loading) {
            if (!this.state.error) {
                additionalClass = " success";
            } else {
                additionalClass = " error";
            }
        }

        return (
            <div className="data-loader">
                <Popup
                    content={t("incident.incidents")}
                    position={"right center"}
                    size='mini'
                    trigger={
                        <div style={{ position: "relative" }}>
                            <Dimmer inverted active={this.state.loading}>
                                <Loader size='small' />
                            </Dimmer>
                            <IncidentIcon className={"data-loader-icon" + additionalClass} />
                        </div>
                    }
                />
            </div>
        );
    }
}

/*
* Maps state from the store to properties used by this class
*/
const mapStateToProps = (store, props) => {
    return {
        scenarioState: store.scenarioState,
        incidentState: store.incidentState,
        ...props
    }
}

/*
* Maps properties to dispatch methods to send actions to the store reducers
*/
const mapDispatchToProps = (dispatch) => {
    return {
        fetchIncidents: (scenarioId) => {
            dispatch(IncidentActions.fetchIncidents(scenarioId));
        },
    }
}

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(SourceLoader);