import React from 'react';
import L from 'leaflet';
import { TileLayer, LayersControl } from 'react-leaflet';
import { OS_MAPS_LOGO_URL, MAPBOX_LOGO_WHITE, MAPBOX_ATTRIBUTION, OS_MAPS_ATTRIBUTION, OPEN_STREET_MAP_ATTRIBUTION, OPEN_TOPO_MAP_ATTRIBUTION } from './AttributionUtil';

const { BaseLayer, } = LayersControl

const OFFLINE_MAPS = window.env.REACT_APP_OFFLINE_MAPS === "true";

export class BaseLayersUtil {

    static ukCities = ['london', 'northeast']

    static getBaseLayers(t, servicesURLs) {

        let baseLayersInfo = { mapLayers: [], startingZoom: [] };
        let defaultBaseMapChecked = true;

        if (OFFLINE_MAPS) {

            const offlineUrl = window.env.REACT_APP_OFFLINE_MAP_URL + "/tile/{z}/{x}/{y}.png";

            baseLayersInfo.startingZoom = 20;
            baseLayersInfo.mapLayers.push(
                <BaseLayer checked={defaultBaseMapChecked} name={t("map.openStreetMap")}>
                    
                    <TileLayer
                        attribution="&amp;copy <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
                        url={ offlineUrl }
                    />
                </BaseLayer>);

            return baseLayersInfo;
        }


        // UK only layers should default to OS base map, customers with EU layers configured should default to OpenStreet Maps
        // TODO: temporary 'hack', ticket exists to externalize base layers into database as well, so this could be configured there
        if (servicesURLs.every(s => this.ukCities.includes(s.layer))) {
            defaultBaseMapChecked = false;
        }

        // If any of the layers configured are in UK - provide OS base map as a possible selection
        if (servicesURLs.some(s => this.ukCities.includes(s.layer))) {

            // Select the OS map, if the default base map is not to be pre-selected
            baseLayersInfo.mapLayers.push(
                <BaseLayer name={"OS Road map"} checked={!defaultBaseMapChecked}>
                    <TileLayer
                        url="https://api.os.uk/maps/raster/v1/zxy/Road_3857/{z}/{x}/{y}.png?key=DHpfsYu4WGXfl7VEu7t8n9edSwseSHAG"
                        minZoom={7}
                        attribution={OS_MAPS_ATTRIBUTION}
                        logo={OS_MAPS_LOGO_URL}
                    />
                </BaseLayer>
            );
            baseLayersInfo.mapLayers.push(
                <BaseLayer name={"OS Outdoor map"}>
                    <TileLayer
                        url="https://api.os.uk/maps/raster/v1/zxy/Outdoor_3857/{z}/{x}/{y}.png?key=DHpfsYu4WGXfl7VEu7t8n9edSwseSHAG"
                        minZoom={7}
                        attribution={OS_MAPS_ATTRIBUTION}
                        logo={OS_MAPS_LOGO_URL}
                    />
                </BaseLayer>
            );
            baseLayersInfo.mapLayers.push(
                <BaseLayer name={"OS Light map"}>
                    <TileLayer
                        url="https://api.os.uk/maps/raster/v1/zxy/Light_3857/{z}/{x}/{y}.png?key=DHpfsYu4WGXfl7VEu7t8n9edSwseSHAG"
                        minZoom={7}
                        attribution={OS_MAPS_ATTRIBUTION}
                        logo={OS_MAPS_LOGO_URL}
                    />
                </BaseLayer>
            );
        }

        // Set the intiial max zoom based on the default base map layer
        baseLayersInfo.startingZoom = defaultBaseMapChecked ? [18] : [20];

        // Push the default base layers at the end, as its checked status depends on the logic above
        baseLayersInfo.mapLayers.push(
            <BaseLayer checked={defaultBaseMapChecked} name={t("map.openStreetMap")}>
                <TileLayer
                    attribution={OPEN_STREET_MAP_ATTRIBUTION}
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
            </BaseLayer>);

        baseLayersInfo.mapLayers.push(
            <BaseLayer name={t("map.openTopoMap")}>
                <TileLayer
                    url="https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png"
                    attribution={OPEN_TOPO_MAP_ATTRIBUTION}
                    transparent={true}
                />
            </BaseLayer>);
        {/*<BaseLayer name={t("map.googleSatelliteMap")}>
                            <TileLayer
                                maxZoom={21}
                                url="http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}"
                                subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                            />
                        </BaseLayer>*/}

        baseLayersInfo.mapLayers.push(
            <BaseLayer name={t("map.satelliteMap")}>
                <TileLayer
                    url="https://api.mapbox.com/styles/v1/urbanaware/cl5sbskm8000c14o95z4rw0ny/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoidXJiYW5hd2FyZSIsImEiOiJjbDVzYm5pd3cyZ21lM2Nyb2QxOGtsY2p0In0.RcZLzz3qZ43TF0frHmP1Iw"
                    attribution={MAPBOX_ATTRIBUTION}
                    transparent={true}
                    tileSize={512}
                    zoomOffset={-1}
                    crossOrigin={true}
                    logo={MAPBOX_LOGO_WHITE}
                />
            </BaseLayer>);

        return baseLayersInfo;

    }

}