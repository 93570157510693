/*
    ./client/components/App.jsx
*/
import L from 'leaflet';
import { Component } from "react";
import { withTranslation } from "react-i18next";
import { FeatureGroup, GeoJSON, LayersControl, Marker, Pane } from 'react-leaflet';
import { connect } from "react-redux";
import { compose } from "redux";
import { AOIStyles } from "../../common/AOIStyles";
import { MapLayerStyles } from '../../common/MapLayerStyles.jsx';

const { Overlay } = LayersControl

class AOILayer extends Component {

    getLayers = () => {
        var markers = [];

        if (!this.props.scenarioState.scenario) {
            return markers;
        }

        var editing = this.props.aoiState.editing;
        var hiddenIds = this.props.mapFilterState.hiddenLayerIds;
        for (var m in this.props.aoiState.aois) {
            var aoi = this.props.aoiState.aois[m]; // eslint-disable-line security/detect-object-injection
            let aoiId = aoi.id;
            if (editing && aoiId === editing.id || hiddenIds.includes(aoiId)) {
                continue;
            }

            if (aoi.geoJson.geometry) {
                if (aoi.geoJson.geometry.type !== 'Point') {
                    aoi.geoJson.geometry.aoiType = aoi.aoiType;
                    var mainLayer = <GeoJSON id={"aoi-layer-" + aoiId}
                        key={"aoi-" + aoiId + "-" + aoi.lastUpdated}
                        data={aoi.geoJson.geometry}
                        style={AOIStyles.getStyle(aoi)}
                        onEachFeature={(feature, layer) => {
                            // If ROUTES are created - add decorations to them (arrows)
                            if(feature.aoiType === 'ENTRY_ROUTE' || feature.aoiType === 'EXIT_ROUTE') {
                                let colour = "blue";
                                if(feature.aoiType === 'ENTRY_ROUTE') {
                                    colour = 'red';               
                                    
                                } else if(feature.aoiType === 'EXIT_ROUTE') {
                                    colour = 'green';  
                                }
                                const polyline = L.polylineDecorator(layer, {
                                    patterns: [
                                    {
                                        offset: 25, repeat: 30,
                                        symbol: L.Symbol.arrowHead({pixelSize: 15, pathOptions: {fillOpacity: 1, weight: 0, color: colour}})
                                    }
                                    ]
                                })
                                layer.on('add', () => polyline.addTo(this.props.leafletMap));
                                layer.on('remove', () => polyline.removeFrom(this.props.leafletMap));
                            }
                        }}
                    />
                    markers.push(mainLayer);
                }

                var leafLayer = new L.GeoJSON(aoi.geoJson.geometry);
                var center = leafLayer.getBounds().getCenter();

                var markerLayer = AOIStyles.getMarker(aoi, center, this.props.t, this.props.userState.user.preferences);
                markers.push(markerLayer);
            }
        }

        if (editing && editing.geoJson) {
            markers.push(
                <GeoJSON key={"aoi-" + editing.id + "EDIT" + editing.lastUpdated}
                    data={editing.geoJson}
                    style={MapLayerStyles.getLightEditingStyle()}
                />
            );
        }

        return markers;
    }

    render() {
        return (
            <FeatureGroup>
                <Pane name="aoi-pane" style={{ zIndex: 196 }}>
                    {this.getLayers()}
                </Pane>
            </FeatureGroup>
        );
    }

    componentWillUnmount() {
        console.log("aoi use will unmount")
    }
}

/*
 * Maps state from the store to properties used by this class
 */
const mapStateToProps = (store, props) => {
    return {
        mapState: store.mapState,
        scenarioState: store.scenarioState,
        aoiState: store.aoiState,
        mapFilterState: store.mapFilterState,
        leafletMap: store.mapState.map,
        userState: store.userState,
        ...props,
    };
};

/*
 * Maps properties to dispatch methods to send actions to the store reducers
 */
const mapDispatchToProps = (dispatch) => {
    return {};
}

export default compose(
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps)
)(AOILayer);
